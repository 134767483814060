import { PropTypes } from "prop-types";
import { Card } from "antd";
import { AppIcon } from "../../../components/AppIcon/AppIcon";
import "./ProductCard.scss";

export const ProductCard = (props) => {
  const { Meta } = Card;
  const { icon, name, id } = props;

  return (
    <Card
      hoverable={id === 1}
      className={id === 1 ? "product__card_outlined" : "product__card"}
      cover={<AppIcon icon={icon} className="product__card-icon" />}
    >
      <Meta className="product__card-meta" title={name} />
      {name != "Préstamo Personal" ? <span>Próximamente</span> : <span>¡Pídelo ya!</span>}
    </Card>
  );
};

ProductCard.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};
