import { useEffect, useState } from "react";
import { Col, Row, Radio, Card, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppButton } from "../../../components/AppButton/AppButton";
import { PropTypes } from "prop-types";
import one from "../../../assets/steps/step-two/steptwo-1.svg";
import "./StepTwo.scss";
import { setWorks } from "../../../store/actions/works";
import { getUserLaboral } from "../../../services/works";

const typesOfLoans = [
  {
    icon: one,
    title: "Empresa Privada/ONGs",
    subtitle: "Trabajo para una empresa y recibo un salario cada mes.",
  },
  {
    icon: one,
    title: "Independiente",
    subtitle: "Trabajo para una empresa y recibo un salario cada mes.",
  },
  {
    icon: one,
    title: "Administrador de Gobierno",
    subtitle: "Trabajo para una empresa y recibo un salario cada mes.",
  },
  {
    icon: one,
    title: "Especialista de Gobierno",
    subtitle: "Trabajo para una empresa y recibo un salario cada mes.",
  },
  {
    icon: one,
    title: "Jubilado",
    subtitle: "Trabajo para una empresa y recibo un salario cada mes.",
  },
];
export const StepTwo = ({ setTypeEmployed, next }) => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((store) => store.auth);
  const { laborales } = useSelector((store) => store.works);
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);

  const validateForm = () => {
    form
      .validateFields()
      .then(() => {
        setIsFormComplete(true);
      })
      .catch(() => {
        setIsFormComplete(false);
      });
  };

  const onChange = (e) => {
    console.log("holiwis");
    validateForm();
    form.setFieldsValue("jobType", e.target.value);
    setTypeEmployed(e.target.value);
  };

  useEffect(async () => {
    try {
      const laboralData = await getUserLaboral(user.cedula, token);
      console.log("LABORALkkk", laboralData);
      dispatch(setWorks(laboralData, {}, {}));
      form.setFieldsValue("jobType", laboralData?.tipo_empleo);
      setTypeEmployed(laboralData?.tipo_empleo);
      validateForm();
    } catch (error) {
      console.log("No tiene datos laborales");
    }
  }, []);

  return (
    <Form
      form={form}
      labelAlign={"right"}
      labelWrap={"true"}
      wrapperCol={{ span: 24 }}
      name="form-work"
      layout={"vertical"}
      initialValues={laborales}
    >
      <Row className="step-two__container">
      <Form.Item name="tipo_empleo">
        <Radio.Group className="step-two__group" onChange={onChange}>
          {typesOfLoans.map((loan) => (
            <Card className="step-two__card" key={loan.title}>
              <Row className="step-two__content">
                <Col xs={12} sm={12} md={12} lg={22} xl={4}>
                  {" "}
                  <img
                    className="step-two__img"
                    src={loan.icon}
                    alt="varon llenando un formulario (men fill a form)"
                  />{" "}
                </Col>
                <Col
                  className="step-two__text"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={22}
                  xl={19}
                >
                  <h4> {loan.title}</h4>
                  <span>{loan.subtitle}</span>
                </Col>
                <Col
                  className="step-two__radio"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={2}
                  xl={1}
                >
                  {" "}
                  <Radio value={loan.title}></Radio>
                </Col>
              </Row>
            </Card>
          ))}
        </Radio.Group>
      </Form.Item>
      </Row>
      <Row justify="center" align="center">
        <AppButton
          htmlType={"submit"}
          action={next}
          className="btn__secondary"
          disabled={!isFormComplete}
        >
          {"Siguiente"}
        </AppButton>
      </Row>
    </Form>
  );
};

StepTwo.propTypes = {
  setTypeEmployed: PropTypes.func,
  next: PropTypes.string,
};
