import { PropTypes } from "prop-types";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../../store/actions/auth';
import { Dropdown, Menu, Typography, Space } from 'antd';
import { FileDoneOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons';
import "./DropdownUser.scss";

export const DropdownUser = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    if (e.key === '/') {
      dispatch(setAuth('', {}));
      window.location.reload();
    }
    navigate(e.key, { replace: true });
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: user ? 'Crear nueva solicitud' : 'Mis solicitudes',
          icon: <FileDoneOutlined />,
          key: user ? '/datos-laborales' : '/mis-solicitudes'
        },
        {
          label: 'Cerrar sesión',
          icon: <LogoutOutlined />,
          key: '/'
        },
      ]}
    />
  );
  return (
    <Dropdown className='dropdown__menu' overlay={menu}>
      <Typography.Link>
        <Space>
          {user}
          <DownOutlined />
        </Space>
      </Typography.Link>
    </Dropdown>
  );
};

DropdownUser.propTypes = {
  user: PropTypes.string,
};
