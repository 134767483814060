import CardLogin from '../../components/CardLogin/CardLogin';
import logo from '../../assets/login/Bankiao-logo2.png';
import { loginBank } from "../../services/banks";
import { getCedulaWithTokenBank } from "../../services/banks";
import './index.scss';

export const LoginAdmin = () => {
  return (
    <>
      <div className='login__page'>
        <div className='login__page_banner'>
          <h1 className='login__page_banner_title'>Administrador de</h1>
          <img className='login__page_banner_logo' src={logo} alt='logo' />
        </div>
        <div className='login__page_card'>
          <CardLogin setVisible={() => { }} login={loginBank} getCedula={getCedulaWithTokenBank}/>
        </div>
      </div>
    </>
  )
}
