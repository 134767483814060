export default [
  'Aeropuerto Internacional de Tocumen',
  'Agencia Panamá Pacífico',
  'Alcaldía de Panamá',
  'Asamblea Nacional de Panamá',
  'Autoridad de la Micro, Pequeña y Mediana Empresa (AMPYME)',
  'Autoridad de Turismo de Panama (ATP)',
  'Autoridad de Aeronáutica Civil',
  'Autoridad de Aseo (AAUD)',
  'Autoridad de Los Recursos Acuáticos de Panamá (ARAP )',
  'Autoridad de Protección al Consumidor y Defensa de la Competencia (ACODECO)',
  'Autoridad del Canal de Panamá',
  'Autoridad del Pasaportes de Panamá',
  'Autoridad del Tránsito y Transporte Terrestre (ATTT)',
  'Autoridad Marítima de Panamá (AMP)',
  'Autoridad Nacional de Administración de Tierras (ANATI )',
  'Autoridad Nacional de Aduanas (ANA)',
  'Autoridad Nacional de los Servicios Públicos (ASEP)',
  'Autoridad Nacional para la Innovación Gubernamental (AIG)',
  'Autoridad Panameña de Seguridad de Alimentos (AUPSA)',
  'Banco de Desarrollo Agropecuario (BDA)',
  'Banco Hipotecario Nacional de Panamá',
  'Banco Nacional de Panamá (BNP)',
  'Benemérito Cuerpo de Bomberos',
  'Caja de Ahorros de Panamá',
  'Caja del Seguro Social',
  'Consejo Nacional de Desarrollo Sostenible (CONADES)',
  'Contraloría General de la República',
  'Defensoría del Pueblo',
  'Despacho Primera Dama',
  'Dirección General de Carrera Administrativa',
  'Dirección General de Contrataciones Públicas',
  'Dirección General del Sistema Penitenciario',
  'Empresa de Generación Eléctrica',
  'Empresa de Transmisión Eléctrica, S.A.',
  'Empresa Nacional de Autopista (ENA)',
  'Fiscalía General de Cuentas',
  'Fiscalía General Electoral',
  'Hospital del Niño',
  'Hospital Santo Tomás',
  'Instituto Conmemorativo Gorgas de Estudios para la Salud',
  'Instituto de Acueductos y Alcantarillados Nacionales (IDAAN)',
  'Instituto de Investigación Agropecuaria de Panamá (IDIAP)',
  'Instituto de Mercadeo Agropecuario (IMA)',
  'Instituto de Seguro Agropecuario (ISA)',
  'Instituto Nacional de Cultura (INAC)',
  'Instituto Nacional de Formación Profesional y Capacitación para el Desarrollo Humano (INADEH)',
  'Instituto Oncológico Nacional',
  'Instituto Panameño Autónomo Coperativo (IPACOOP)',
  'Instituto Panameño de Deportes (PANDEPORTES)',
  'Instituto Panameño de Habilitación Especial (IPHE)',
  'Instituto para la Formación y Aprovechamiento de Recursos Humanos (IFARHU)',
  'Lotería Nacional de Beneficencia',
  'Mercados Nacionales de la Cadena de Frío',
  'Metro de Panamá',
  'Ministerio de Ambiente de Panamá',
  'Ministerio de Comercio e Industrias (MICI)',
  'Ministerio de Desarrollo Agropecuario (MIDA)',
  'Ministerio de Desarrollo Social (MIDES)',
  'Ministerio de Economía y Finanzas (MEF)',
  'Ministerio de Educación (MEDUCA)',
  'Ministerio de Gobierno',
  'Ministerio de la Presidencia',
  'Ministerio de Obras Públicas',
  'Ministerio de Relaciones Exteriores',
  'Ministerio de Salud (MINSA)',
  'Ministerio de Seguridad Pública (MINSEG)',
  'Ministerio de Trabajo y Desarrollo Laboral (MITRADEL)',
  'Ministerio de Vivienda y Ordenamiento Territorial',
  'Ministerio Público de Panamá',
  'Oficina de Electrificación Rural',
  'Órgano Judicial de la República de Panamá',
  'Policía Nacional de Panamá',
  'Procuraduría de la Administración',
  'Registro Público de Panamá',
  'Secretaría Nacional de Energía',
  'Secretaria Nacional de Ciencia, Tecnología e Innovación (SENACYT)',
  'Secretaría Nacional de Discapacidad (SENADIS)',
  'Secretaría Nacional de Niñez, Adolescencia y Familia (SENNIAF)',
  'Servicio Nacional de Fronteras (SENAFRONT )',
  'Servicio Nacional de Migración Panamá',
  'Sistema de Ahorro y Capitalización de Pensiones de los Servidores Públicos (SIACAP)',
  'Sistema Nacional de Protección Civil (SINAPROC)',
  'Superintendencia de Bancos de Panamá',
  'Superintendencia de Seguros de Panamá',
  'Superintendencia del Mercado de Valores de Panamá',
  'Tribunal Administrativo de Contrataciones Públicas (TACP)',
  'Tribunal Administrativo Tributario',
  'Tribunal de Cuentas',
  'Tribunal Electoral',
  'Universidad Autónoma de Chiriquí (UNACHI)',
  'Universidad de Panamá',
  'Universidad Especializasa de las Américas (UDELAS)',
  'Universidad Marítima Internacional de Panamá',
  'Universidad Tecnológica de Panamá (UTP)',
  'Zona Franca de Barú',
  'Zona Libre de Colón',
  'Asamblea Nacional',
  'Contraloria General De La Republica',
  'Defensoria Del Pueblo',
  'Fiscalía De Cuentas',
  'Fiscalía General Electoral',
  'Ministerio De Ambiente',
  'Ministerio De Comercio E Industrias',
  'Ministerio De Cultura',
  'Ministerio De Desarrollo Agropecuario',
  'Ministerio De Desarrollo Social',
  'Ministerio De Economia Y Finanzas',
  'Ministerio De Educacion',
  'Ministerio De Gobierno',
  'Ministerio De La Presidencia',
  'Ministerio De Obras Publicas',
  'Ministerio De Relaciones Exteriores',
  'Ministerio De Salud',
  'Ministerio De Seguridad Publica',
  'Ministerio De Trabajo Y Desarrollo Laboral',
  'Ministerio De Vivienda Y Ordenamiento Territorial',
  'Organo Judicial',
  'Otros Gastos De La Administracion',
  'Procuraduría De La Administración',
  'Procuraduría General De La Nación',
  'Tribunal Administrativo Tributario',
  'Tribunal De Cuentas',
  'Tribunal Electoral',
];
