// import { PropTypes } from'prop-types";
import { useEffect } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Form, Input, Row, Select, Popover } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../../components/AppButton/AppButton";
import { getQuota } from "../../../services/calculator";
import { validate } from "cedula-panama";
import { setRequest } from "../../../store/actions/request";
import { setAnalytics } from "../../../services/setAnalytics";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../store/actions/auth";
import moment from "moment";
import "./CardCalculator.scss";
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

export const CardCalculator = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [dataRequest, setDataRequest] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onNumQuotasChange = (value) => {
    form.setFieldsValue({ numCuotas: value });
  };

  const onCleanForm = () => {
    form.setFieldsValue({
      monto: "",
      cuota: "",
      numCuotas: "",
    });
    setShow(false);
  };

  const onFinish = async (values) => {
    setDataRequest(values);
    const resp = await getQuota({
      monto_solicitado: values.monto,
      plazo: values.numCuotas,
      tasa_interes: 15,
    });
    console.log("RESPONSE CALCULADORA", resp);
    form.setFieldsValue({
      cuota: `${resp.letra_mensual.toFixed(2)}$/mes`,
    });
    setAnalytics("calculator", `Btn_Calculator_Calcular_${values.cedula}`);
    dispatch(
      setAuth("", {
        cedula: values.cedula,
        email: "",
        requestByCalculator: true,
      })
    );
    setLoading(false);
    setShow(true);
  };

  const onCalculate = async () => {
    setAnalytics("calculator", "Btn_Calculator_Calcular");
    console.log("calculando...");
    const valid = await form.validateFields();
    console.log("VALID cal", valid);
    setLoading(true);
  };

  const onRegister = () => {
    console.log(dataRequest);
    dispatch(
      setRequest({
        cedula_usuario: dataRequest.cedula,
        fecha_solicitud: moment().format("YYYY/MM/DD"),
        tipo_solicitud: "Préstamo personal",
        entidad_receptora: "Bankiao",
        estado_solicitud: "registrada",
        monto_solicitud: dataRequest.monto,
        plazo_solicitud: dataRequest.numCuotas,
      })
    );
    setLoading(true);
    navigate("/registro");
  };

  const hoverContent = () => {
    return (
      <div>
        <p>
          Para el cálculo se utilizaron los siguientes datos promedios,
          <br />
          los cuales varían dependiendo de la institución y el perfil de riesgo{" "}
          <br />
          de cada persona. <br />
          Tasa de interés: 15% anual.
          <br />
          * No incluye comisiones, gastos de seguros y demás gastos <br />{" "}
          dependiendo de cada entidad financiera.
          <br />
          <br />
          El cálculo aquí reflejado constituye únicamente una referencia y<br />
          no representa ningún compromiso ni obligación por parte <br />
          de Bankiao. Todo financiamiento está sujeto a evaluación y
          <br />
          aprobación de crédito y al cumplimiento de las políticas de cada
          <br />
          institución financiera.
          <br />
          <br />
          El uso de esta herramienta no garantiza la aprobación de un crédito.
          <br />
          Para mayores detalles ver las Condiciones del Servicio y
          <br />
          la Política de tratamiento de datos personales.
        </p>
      </div>
    );
  };
  return (
    <Card className="calculator" hoverable>
      <Row
        align="center"
        justify="center"
        className="calculator__container-title"
      >
        <h1 className="calculator__title">
          Calcula la cuota de tu próximo préstamo
        </h1>
      </Row>
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={24} >
          <Col xs={24} sm={24} md={24} lg={13} xl={12}>
            <Form.Item
              label={"Cédula"}
              name="cedula"
              rules={[
                { required: true, message: "Ingresar cédula" },
                () => ({
                  validator(_, value) {
                    if (validate(value).isValid) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(""));
                  },
                }),
              ]}
            >
              <Input
                className="calculator__input"
                placeholder="Cédula con guiones"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={11} xl={12}>
            <Form.Item
              label={"Cuotas"}
              name="numCuotas"
              rules={[{ required: true, message: "Ingresar cuota" }]}
            >
              <Select
                className="calculator__select"
                placeholder="Selecciona el número de cuotas"
                onChange={onNumQuotasChange}
                allowClear
                size="large"
              >
                <Option value="36">36</Option>
                <Option value="24">24</Option>
                <Option value="18">18</Option>
                <Option value="12">12</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Monto a solicitar"
              name="monto"
              rules={[
                { required: true, message: "Ingresar Monto" },
                () => ({
                  validator(_, value) {
                    if (/^[0-9]/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(""));
                  },
                }),
              ]}
            >
              <Input
                addonBefore={"$US"}
                className="calculator__input"
                placeholder="Ingresa tu monto"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label="Cuota apróx." name="cuota">
              <Input
                readOnly
                className="calculator__input-cuota"
                placeholder="Cuota"
                disabled
                style={{
                  background: "white",
                  cursor: "default",
                  color: "black",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            className="calculator__container-action"
            xs={24}
            sm={22}
            md={24}
            lg={24}
            xl={24}
          >
            {!show && (
              <Form.Item shouldUpdate>
                {() => (
                  <AppButton
                    loading={loading}
                    action={onCalculate}
                    disabled={
                      !form.isFieldsTouched(
                        ["cedula"] && ["numCuotas"] && ["monto"]
                      ) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                    className={"btn__secondary"}
                  >
                    Calcular
                  </AppButton>
                )}
              </Form.Item>
            )}

            {show && (
              <>
                <AppButton action={onRegister} className="btn__primary">
                  ¡Quiero una tasa especial!
                </AppButton>
                <Form.Item shouldUpdate>
                  {() => (
                    <AppButton
                      loading={loading}
                      action={onCleanForm}
                      type={"link"}
                      className={"btn__link"}
                    >
                      Volver a calcular
                    </AppButton>
                  )}
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
        <Col
          className="calculator__info"
          xs={24}
          sm={22}
          md={22}
          lg={24}
          xl={24}
        >
          <a>
            {" "}
            <small>Información para calcular las cuotas</small>
          </a>
          <Popover
            placement="bottom"
            content={hoverContent}
            style={{ width: 100 }}
            arrowPointAtCenter
          >
            <InfoCircleOutlined
              style={{
                marginTop: "5px",
                color: "#13005A",
              }}
              color="primary"
            />
          </Popover>{" "}
        </Col>
      </Form>
    </Card>
  );
};

// CardCalculator.propTypes = {
//   title: PropTypes.string.isRequired,
//   price: PropTypes.string.isRequired,
//   buttonType: PropTypes.string.isRequired,
//   features: PropTypes.arrayOf(PropTypes.string),
// };
