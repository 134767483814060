import { useState, useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Space,
} from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import { validate } from "cedula-panama";
import dayjs from "dayjs";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../../components/AppButton/AppButton";
import ModalAuth from "../../../components/ModalAuth/ModalAuth";
import { registerUser, loginUser } from "../../../services/user";
import { setAuth } from "../../../store/actions/auth";
import "./CardRegister.scss";
import { setAnalytics } from "../../../services/setAnalytics";

export const CardRegister = ({ setShowTitle, setShowSpinner }) => {
  const [modalAuth, setModalAuth] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formOne] = Form.useForm();
  const [formTwo] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [show, setShow] = useState(true);
  const [error, setError] = useState("");
  const [checked, setChecked] = useState(true);
  const { user } = useSelector((store) => store.auth);
  const handleCancelAuth = () => {
    setModalAuth(false);
  };

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinishTwo = async (values) => {
    console.log("VALUES REGISTER 2", values);
    setError("");
    const form = await formOne.getFieldsValue(true);
    console.log("FORMULARIOOOOO", form);
    try {
      setLoadingRegister(true);
      const respRegister = await registerUser({
        cedula: form.cedula,
        nombre: form.name,
        apellido: form.lastName,
        password: form.password,
        fecha_nacimiento: dayjs(form.birthDay).format("YYYY/MM/DD"),
        correo: form.email,
        telefono: form.phone,
      });
      if (respRegister.Info === "Este usuario ya existe") {
        message.warning("Ya tienes una cuenta");
      } else {
        setShowSpinner(true);
        const respLogin = await loginUser({
          username: form.email,
          password: form.password,
        });
        console.log("RESPONSE LOGIN", respLogin);

        dispatch(
          setAuth(respLogin.access_token, {
            ...user,
            email: form.email,
            cedula: form.cedula,
          })
        );
        setTimeout(() => {
          navigate("/datos-laborales");
        }, 2000);
      }
    } catch (error) {
      console.log("Error", error);
      setLoadingRegister(false);
      setError("Usuario ya registrado");
    }
  };

  const onSubmitTwo = async () => {
    try {
      setAnalytics("registroDos", "Btn_RegistroDos_Registrar");
      const valid = await formTwo.validateFields();
      console.log("calculando...", valid);
      console.log("loadinggggg");
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitOne = async () => {
    try {
      setAnalytics("registroUno", "Btn_RegistroUno_Siguiente");
      const valid = await formOne.validateFields();
      console.log("calculando...", valid);
      if (valid) {
        setLoading(true);
        setTimeout(() => {
          setShow(false);
          setShowTitle(false);
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeDate = (date, dateString) => {
    console.log("DATE");
    console.log(date, dateString);
  };

  return (
    <Card className="card-register">
      {show ? (
        <Form layout="vertical" form={formOne} name="form-one">
          <>
            <Space
              direction="vertical"
              gutter={24}
              size={0}
              style={{ width: "100%" }}
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Cédula"
                  name="cedula"
                  tooltip="Ej. 8-123-123"
                  hasFeedback
                  rules={[
                    { required: true, message: "Ej. 8-123-123" },
                    () => ({
                      validator(_, value) {
                        if (validate(value).isValid) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error("Formato no válido"));
                      },
                    }),
                  ]}
                >
                  <Input
                    className="card-register__input"
                    defaultValue={user.cedula}
                    placeholder="Ingresa cédula con guiones"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  hasFeedback
                  rules={[{ required: true, message: "Ingresar Contraseña" }]}
                >
                  <Input.Password
                    className="card-register__input"
                    placeholder="Ingresa tu contraseña"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Valida tu contraseña"
                  name="passwordRepeat"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    { required: true, message: "Validar Contraseña" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error("Las contraseñas no coinciden")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Ingresa tu contraseña"
                    className="card-register__input"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Número de Teléfono"
                  name="phone"
                  hasFeedback
                  tooltip="Ej. 6010-2030"
                  rules={[
                    { required: true, message: "Ej. 6010-2030" },
                    () => ({
                      validator(_, value) {
                        if (/^[0-9]{4}-[0-9]{4}$/.test(value)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error());
                      },
                    }),
                  ]}
                >
                  <Input
                    addonBefore={"+507"}
                    className="card-register__input"
                    placeholder="9887-9850"
                  />
                </Form.Item>
              </Col>
            </Space>
            <Row className="card-register__container-action" gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                >
                  <a
                    target={"_blank"}
                    href="https://firebasestorage.googleapis.com/v0/b/panalytics-frontend.appspot.com/o/Aviso_de_Privacidad_Bankiao.pdf?alt=media&token=04db600f-f60f-4e87-b1a0-1e4a8d564ac7"
                    rel="noreferrer"
                  >
                    Autorizo a Panalytics el Tratamiento de mis datos personales
                  </a>
                </Checkbox>
              </Col>
            </Row>

            <Form.Item shouldUpdate>
              {() => (
                <Row className="card-register__container-action">
                  <Col
                    className="card-register__container-action"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                  >
                    <AppButton
                      loading={loading}
                      action={onSubmitOne}
                      className="btn__secondary"
                      disabled={
                        !formOne.isFieldsTouched(true) ||
                        !!formOne
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length ||
                        !checked
                      }
                    >
                      {loading ? "Enviando" : "Siguiente"}
                    </AppButton>
                  </Col>
                </Row>
              )}
            </Form.Item>
            <Row>
              <Col
                className="card-register__btn-container"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <Button
                  className="card-register__btn-login"
                  type="text"
                  onClick={() => {
                    setModalAuth(true);
                  }}
                >
                  <p>
                    {" "}
                    <b>Iniciar Sesión</b>, ya tengo cuenta!{" "}
                  </p>
                </Button>
              </Col>
            </Row>
          </>
        </Form>
      ) : (
        <Form
          layout="vertical"
          form={formTwo}
          name="form-two"
          onFinish={onFinishTwo}
        >
          <>
            <Space
              direction="vertical"
              gutter={24}
              size={0}
              style={{ width: "100%" }}
            >
              <Col xs={12} sm={12} md={12} lg={24} xl={24}>
                <Form.Item
                  label="Nombre"
                  name="name"
                  hasFeedback
                  rules={[{ required: true }]}
                >
                  <Input
                    className="card-register__input"
                    placeholder="Ingresa tu nombre"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={24} xl={24}>
                <Form.Item
                  label="Apellido"
                  name="lastName"
                  hasFeedback
                  rules={[{ required: true }]}
                >
                  <Input
                    autoComplete="off"
                    className="card-register__input"
                    placeholder="Ingresa tu apellido"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={24} xl={24}>
                <Form.Item
                  label={"Fecha de Nacimiento"}
                  name={"birthDay"}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Campo obligatorio",
                    },
                  ]}
                >
                  <DatePicker
                    className="data__picker"
                    locale={locale}
                    style={{ width: "100%" }}
                    placeholder={"Ingrese fecha de nacimiento"}
                    onChange={onChangeDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={24} xl={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Ingresa tu correo",
                    },
                  ]}
                  hasFeedback
                  label="Correo electrónico"
                >
                  <Input
                    className="card-register__input"
                    placeholder="Ingresa tu correo electrónico"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={24} xl={24}>
                <Form.Item
                  label="Validar correo eléctronico"
                  name="emailVerify"
                  dependencies={["email"]}
                  hasFeedback
                  rules={[
                    { required: true, message: "Valida tu correo" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("email") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error("Las correos electrónicos no coinciden")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    className="card-register__input"
                    placeholder="Ingresa nuevamente tu correo electrónico"
                  />
                </Form.Item>
              </Col>
            </Space>
            {error && <small>{error}</small>}
            <Form.Item shouldUpdate>
              {() => (
                <Row className="card-register__container-action">
                  <Col
                    className="card-register__container-action"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={24}
                  >
                    <AppButton
                      action={onSubmitTwo}
                      className="btn__secondary"
                      disabled={
                        !formOne.isFieldsTouched(true) ||
                        !!formOne
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      {loadingRegister ? "Registrando" : "Registrar"}
                    </AppButton>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </>
        </Form>
      )}
      <ModalAuth
        visible={modalAuth}
        setVisible={setModalAuth}
        handleCancelAuth={handleCancelAuth}
      />
    </Card>
  );
};

CardRegister.propTypes = {
  setShowTitle: PropTypes.func,
  setShowSpinner: PropTypes.func,
};
