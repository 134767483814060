import axiosInstance from './auth';

const HOST = process.env.REACT_APP_HOST_PANALYTICS;

const loginUser = async (user) => {
  const data = Object.keys(user)
    .map((key) => `${key}=${encodeURIComponent(user[key])}`)
    .join('&');
  const response = await axiosInstance({
    url: `${HOST}/clientes/login`, headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const registerUser = async (data) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/registro`,
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getUser = async (cedula, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/fetch/datos_usuario?cedula=${cedula}`,
    headers: { 'Accept': 'application/json', 'credentials': `${token}` },
    method: 'GET',
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getCedulaWithTokenUser = async (access_token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/auth/extraer_cedula_desde_token`, headers: { 'Accept': 'application/json' },
    method: 'POST',
    data: {
      access_token,
    },
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

export {
  getCedulaWithTokenUser,
  registerUser,
  loginUser,
  getUser
};