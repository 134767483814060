import { Outlet, useLocation, Link } from "react-router-dom";
import React, { useState } from "react";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  FileOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
const { Header, Sider, Content } = Layout;
import logo from '../assets/login/Bankiao-logo2.png';
import "./AdminLayout.scss";

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { pathname } = useLocation();
  const items = [
    {
      key: "1",
      icon: <HomeOutlined className="icon" />,
      label: "Inicio",
      to: "/admin/inicio",
    },
    {
      key: "2",
      icon: <FileOutlined className="icon" />,
      label: "Formularios",
      to: "/admin/formulario",
    },
    {
      key: "3",
      icon: <LogoutOutlined className="icon" />,
      label: "Salir",
      to: "/admin",
    },
  ];

  return (
    <Layout className="layout">
      <Sider className="sider" trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <img className="logo_nav" src={logo} alt="logo" />
        </div>
        <Menu
          className="menu"
          pathname={pathname}
          mode="inline"
          defaultSelectedKeys={["1"]}
        >
          {items.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.to}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0 20px",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "90vh",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
