import { PropTypes } from "prop-types";
import "./Container.scss";

export const Container = (props) => {
  const { fluid, className = "", children } = props;

  return (
    <div className={`container ${fluid ? "container--fluid" : ""} ${className}`}>
      {children}
    </div>
  )
}

Container.propTypes = {
  fluid: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
