import { types } from '../types/types';

export const setAuth = (token, user ) => ({
  type: types.login,
  payload: {
    token: token || sessionStorage.getItem('token'),
    user,
  },
});

export const logoutUser = () => ({
  type: types.logout,
});

export const startLoginUser = () => async (dispatch) => {
  console.log(dispatch);
};

export const startLogoutUser = () => async (dispatch) => {
  dispatch(logoutUser());
};
