import { WorkSteps } from '../../containers/WorkSteps/WorkSteps';

export const RegisterWork = () => {

  return (
    <>
      <WorkSteps />
    </>
  );
};
