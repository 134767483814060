import { useRoutes } from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactGA from "react-ga4";
import routes from "./router";
import "./App.scss";

function App() {
  const { user } = useSelector((state) => state.auth);
  const routing = useRoutes(routes(user.cedula));
  ReactGA.initialize("G-W7GP351ZZ1");

  return <>{routing}</>;
}

export default App;
