import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, InputNumber, Card, Col, Radio, Grid } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import { PropTypes } from 'prop-types';
import { AppButton } from '../../../components/AppButton/AppButton';
import { createPoliticsRetiredtaff } from '../../../services/banks';
const { useBreakpoint } = Grid;
moment.locale('es');

export function JubiladosPensionados({ prestamo, loading, policies, getPolicies }) {
  const screens = useBreakpoint();
  console.log('SCREENS', screens)
  const { user, token } = useSelector((store) => store.auth);
  const [value, setValue] = useState('');
  const [form] = Form.useForm();
  const saveDataRetired= async (values) => {
    console.log('VALUES SALARIED', values);
    await createPoliticsRetiredtaff({
      token,
      nombre_banco: user.banco || sessionStorage.getItem("bank"),
      ...values,
    });
    await getPolicies();
  };

  useEffect(() => {
    console.log('POLI', policies);
    form.setFieldsValue({
      ...policies,
    });
  }, []);
  return (
    <Card title={prestamo} loading={loading} >
      <Form
        form={form}
        labelAlign={'left'}
        autoComplete="off"
        layout={'vertical'}
        onFinish={saveDataRetired}
      >
        <h4>Requerimientos</h4>
        <Form.Item
          label="Antigüedad Laboral (meses)"
          name="antiguedad_laboral"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%'}}
            min={1}
            value={value}
            onChange={setValue}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Salario mínimo requerido"
          name="salario_minimo"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            addonAfter="$"
            style={{ width: '100%'}}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Edad mínima"
          name="edad"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            min={1}
            max={99}
            value={value}
            onChange={setValue}
            style={{ width: '100%'}}
          ></InputNumber>
        </Form.Item>
        <Form.Item
          label="Puntaje  mínimo de la APC"
          name="puntaje_apc"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            min={0}
            max={500}
            value={value}
            onChange={setValue}
            style={{ width: '100%'}}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Nivel de endeudamiento máximo (global)"
          name="nivel_endeudamiento_global"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            addonAfter="%"
            min={0}
            max={100}
            style={{ width: '100%'}}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Nivel de endeudamiento máximo (consumo)"
          name="nivel_endeudamiento_consumo"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            addonAfter="%"
            min={0}
            max={100}
            style={{ width: '100%'}}
          ></InputNumber>
        </Form.Item>
        <Form.Item
          label="Tipo de pago"
          name="tipo_pago"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
          style={{ width: '100%'}}
        >
          <Col>
            {(
              <Radio.Group
                style={{
                  padding: '10px 0',
                }}
                defaultValue={policies && Object.values(policies).length !== 0 && policies['tipo_pago']}
              >
                <Radio value={'Voluntario'}>Voluntario</Radio>
                <Radio value={'Directo'}>Directo</Radio>
              </Radio.Group>
            )}
          </Col>
        </Form.Item>
        <br />
        <h4>Variables de oferta Bancaria</h4>
        <Form.Item
          label="Tasa mínima ofrecida"
          name="tasa_minima"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            addonAfter="%"
            min={0}
            max={100}
            style={{ width: '100%'}}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Tasa máxima ofrecida"
          name="tasa_maxima"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            addonAfter="%"
            min={0}
            max={100}
            style={{ width: '100%'}}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Plazo máximo en años"
          name="plazo"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <InputNumber
            min={1}
            max={36}
            value={value}
            onChange={setValue}
            style={{ width: '100%'}}

          ></InputNumber>
        </Form.Item>
        <AppButton
            htmlType={'submit'}
            className="btn__secondary"
            justify-content="center"
          >
            {'Guardar'}
          </AppButton>
      </Form>
    </Card>
  );
}

JubiladosPensionados.propTypes = {
  policies: PropTypes.object,
  prestamo: PropTypes.string,
  loading: PropTypes.boolean,
  form: PropTypes.object,
  getPolicies: PropTypes.function,
};
