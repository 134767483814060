import { PropTypes } from "prop-types";
import { Button } from "antd";
import "./AppButton.scss";

export const AppButton = (props) => {
  const {
    type = "",
    htmlType = "",
    className = "",
    children,
    action,
    loading,
    disabled = false,
  } = props;
  return (
    <Button
      disabled={disabled}
      htmlType={htmlType}
      loading={loading}
      onClick={action}
      type={type}
      className={`app-button ${className}`}
    >
      {children}
    </Button>
  );
};

AppButton.propTypes = {
  type: PropTypes.string,
  htmlType: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  action: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
