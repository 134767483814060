import axiosInstance from './auth';

const HOST = process.env.REACT_APP_HOST_PANALYTICS;

const createLaboralData = async (data, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/crear/datoslaborales`, headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token}` },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const createLaboralIndepentData = async (data, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/crear/laborales_independientes`, headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token}` },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const createFinancialData = async (data) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/crear/datosfinancieros`, headers: { 'Accept': 'application/json' },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const createDocumentsData = async (data, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/crear/documentos_usuario`, headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token}` },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const createDocumentsRetiredData = async (data, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/crear/documentos_jubilado`, headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token}` },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const createDocumentsIndepentData = async (data, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/crear/documentos_independiente`, headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token}` },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getUserLaboral = async (document, token) => {
  console.log('LABORAL documents', document)
  const response = await axiosInstance({
    url: `${HOST}/clientes/fetch/datos_laborales?cedula=${document}`,
    headers: { 'Accept': 'application/json', 'credentials': `${token}` },
    method: 'GET',
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getUserFinancial = async (document, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/fetch/datos_financieros?cedula=${document}`,
    headers: { 'Accept': 'application/json', 'credentials': `${token}` },
    method: 'GET',
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getUserDocuments = async (document, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/fetch/documentos_usuario?cedula=${document}`,
    headers: { 'Accept': 'application/json', 'credentials': `${token}` },
    method: 'GET',
  });
  return response.data;
}


export {
  createDocumentsRetiredData,
  createDocumentsIndepentData,
  createLaboralIndepentData,
  createLaboralData,
  createFinancialData,
  createDocumentsData,
  getUserLaboral,
  getUserFinancial,
  getUserDocuments
};