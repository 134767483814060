import { Outlet } from "react-router-dom";
import { AppHeader } from "../components/AppHeader/AppHeader";
import { AppFooter } from "../components/AppFooter/AppFooter";
import "./LandingLayout.scss";

const LandingLayout = () => {
  return (
    <div className="landing-page">
      <AppHeader />
      <Outlet />
      <AppFooter />
    </div>
  );
};

export default LandingLayout;
