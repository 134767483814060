import { Card, Form, Input, Row, Select, message } from 'antd';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import gif from "../../../assets/steps/step-five/gif.png";
import { AppButton } from "../../../components/AppButton/AppButton";
import { Spinner } from '../../../components/Spinner/Spinner';
import { createRequestData } from '../../../services/request';
import { setRequest } from '../../../store/actions/request';
import './StepFive.scss';
const { Option } = Select;

export const StepFive = ({ typeEmployed, next }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false);
  const { user, token } = useSelector((store) => store.auth);
  const { request } = useSelector((store) => store.request);

  const onChangeSelect = (value) => {
    validateForm();
    dispatch(setRequest({
      ...request,
      plazo_solicitud: value,
    }));
  };
  const onChange = (e) => {
    validateForm();
    dispatch(
      setRequest(
        {
          ...request,
          cedula_usuario: user.cedula,
          fecha_solicitud: moment().format('YYYY/MM/DD'),
          tipo_solicitud: 'Préstamo personal',
          entidad_receptora: 'Bankiao',
          estado_solicitud: 'registrada',
          monto_solicitud: e.target.value,
        }
      )
    );
  };

  const validateForm = () => {
    form
      .validateFields()
      .then(() => {
        setIsFormComplete(true);
      })
      .catch(() => {
        setIsFormComplete(false);
      });
  };
  const saveRequest = async () => {
    let newRequest = {};
    if (!user.requestByCalculator) {
      newRequest = {
        cedula_usuario: user.cedula,
        fecha_solicitud: moment().format("YYYY/MM/DD"),
        tipo_solicitud: "Préstamo personal",
        entidad_receptora: "Bankiao",
        estado_solicitud: "registrada",
        monto_solicitud: 0,
        plazo_solicitud: 0,
      };
    }
    try {
      setShowSpinner(true);
      if (typeEmployed === "Jubilado") {
        await createRequestData({
          cedula_usuario: user.cedula,
          ...newRequest,
          ...request,
        }, token);
        setShowSpinner(false);
        next();
        return;
      }
      if (typeEmployed === "Independiente") {
        await createRequestData({
          token,
          cedula_usuario: user.cedula,
          ...newRequest,
          ...request,
        });
        setShowSpinner(false);
        next();
        return;
      }
      await createRequestData({
        token,
        cedula_usuario: user.cedula,
        ...newRequest,
        ...request,
      }, token);
      setShowSpinner(false);
      next();
    } catch (error) {
      setShowSpinner(false);
      message.error(
        'Ocurrió un error al guardar datos, por favor intenta luego.'
      );
    }
  };

  useEffect(() => {
    form.setFieldsValue(request);
    validateForm();
    console.log('REQUEST', request)

  }, [])

  return (
    <Form
      className="step-four"
      form={form}
      labelAlign={'right'}
      labelWrap={'true'}
      name="form-work"
      layout={'vertical'}
      autoComplete="off"
      initialValues={request}
    >
      <Row className="step-five__container">
        {showSpinner && (
            <Spinner />
        )}
        <p>
          Segun la información suministrada, hemos identificado algunas entidades
          bancarias a las que podrías aplicar.
        </p>
        {user.requestByCalculator ? <img src={gif} /> :
          <Card
            style={{
              marginTop: 5,
              marginBottom: 15,
            }}>
            <Form.Item
              label="¿Cuál es el monto que deseas solicitar?"
              name="monto_solicitud"
              rules={[
                { required: true, message: 'Ingresar Monto' },
                () => ({
                  validator(_, value) {
                    if (/^[0-9]/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(''));
                  },
                }),
              ]}
            >
              <Input
                addonBefore={'$US'}
                className="calculator__input"
                placeholder="Ingresa tu monto"
                allowClear
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item
              label={'¿En cuantas cuotas deseas pagar?'}
              name="plazo_solicitud"
              rules={[{ required: true, message: 'Ingresar cuota' }]}
            >
              <Select
                className="calculator__select"
                placeholder="Selecciona el número de cuotas"
                allowClear
                size="large"
                onChange={onChangeSelect}
              >
                <Option value={36}>36</Option>
                <Option value={24}>24</Option>
                <Option value={18}>18</Option>
                <Option value={12}>12</Option>
              </Select>
            </Form.Item>

          </Card>
        }
        <Row justify="center" align="center">
          <AppButton
            htmlType={"submit"}
            action={saveRequest}
            className="btn__secondary"
            disabled={!isFormComplete}
          >
            {"Siguiente"}
          </AppButton>
        </Row>
      </Row>
    </Form>
  );
};

StepFive.propTypes = {
  typeEmployed: PropTypes.object,
  next: PropTypes.string,
};
