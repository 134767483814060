// import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { Col,  Progress, Row, Table } from 'antd';
import { ModalAdminClients } from '../../components/ModalClients/ModalClients';
// import moment from 'moment';

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {
  getRequestByCedula
} from '../../services/request';
import {
  getRequestByBankAndAgent
} from '../../services/banks';
import { getUser } from '../../services/user';
import { getUserFinancial, getUserDocuments, getUserLaboral } from '../../services/works';
import "./index.scss";
const columns = [
  {
    title: 'Fecha de Solicitud',
    dataIndex: 'fecha_solicitud',
    key: 'fecha_solicitud',
    sorter: (a, b) => a.fecha_solicitud.localeCompare(b.fecha_solicitud),
    // sorter: (a, b) => a.registerDate.length - b.registerDate.length,
    ellipsis: true,
  },
  {
    title: 'Cédula',
    dataIndex: 'cedula_usuario',
    key: 'cedula_usuario',
    sorter: (a, b) => a.cedula.localeCompare(b.cedula_usuario),
  },
/*   {
    title: 'Apellidos',
    key: 'apellido',
    dataIndex: 'apellido',
    sorter: (a, b) => a.apellido.localeCompare(b.apellido),
  },
  {
    title: 'Nombres',
    key: 'nombre',
    dataIndex: 'nombre',
    sorter: (a, b) => a.nombre.localeCompare(b.nombre),
  }, */
  {
    title: 'Solicitud',
    dataIndex: 'tipo_solicitud',
    key: 'tipo_solicitud',
  },
  {
    title: 'Estado',
    dataIndex: 'estado_solicitud',
    key: 'tipo_solicitud',
  },
];

export const AdminHome = () => {
  const { token } = useSelector((store) => store.auth);
  console.log('TOKEBBBBBBBBBBB', token)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [requests, setRequests] = useState([]);
  const [client, setClient] = useState({});
  // const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getAllDataRequest = async (event, record, recordIndex) => {
    const data = { ...record };
    try {
      console.log('REDORDDD', record, recordIndex);
      showModal();

      const personalData = await getUser(record.cedula_usuario, token);
      data.personalData = personalData;

      const laboralData = await getUserLaboral(record.cedula_usuario, token);
      data.laboralData = laboralData;

      const financialData = await getUserFinancial(record.cedula_usuario, token);
      data.financialData = financialData;

      const requestData = await getRequestByCedula(record.cedula_usuario, token);
      data.requestData = requestData;

      const documentsData = await getUserDocuments(record.cedula_usuario, token);
      data.documentsData = documentsData;

      console.log(laboralData, financialData, requestData, documentsData);
    } catch (error) {
      console.log('ERROR REQUESTS', error);
    } finally {
      console.log('DATA REGISTRO', data);
      setClient(data);
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('parasssms', pagination, filters, sorter, extra);
  };

/*   const getDownload = async () => {
    try {
        const worksheet = XLSX.utils.json_to_sheet(formattedChatHistory);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Chat History");

      // Guardar el archivo Excel
      XLSX.writeFile(
        workbook,
        `chat_${selectedProject}_${selectedEnvironment}.xlsx`,
        {
          bookType: "xlsx",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  }; */

  const getRequestByUsers = async () => {
    try {
      let requestsByUser = await getRequestByBankAndAgent(token);
      console.log('USER', requestsByUser)
      if (requestsByUser.length !== 0) {
        setRequests(requestsByUser);
      }
    } catch (error) {
      console.log('REQUEST BY BANKS', error)
    }
  }

  useEffect(() => {
    getRequestByUsers();
  }, [client]);

  return (
    <>
     <Row gutter={24}>
       {/*  <Col xs={24} md={3}>
          <DatePicker defaultValue={moment('16/06/2022', dateFormatList[0])} format={dateFormatList} />
        </Col>
        <Col xs={24} md={3}>
          <DatePicker defaultValue={moment('16/06/2022', dateFormatList[0])} format={dateFormatList} />
        </Col> */}
     {/*    <Col>
          <Row gutter={24}>
            <Button size='middle' className='btn-green' onClick={getDownload}>Descargar<FileExcelOutlined /></Button>
          </Row>
        </Col> */}
      </Row> 

      <Row gutter={24}>
        <Col xs={24} md={18}>
          <Table
            className='table_clients'
            columns={columns}
            dataSource={requests}
            onChange={onChange}
            onRow={(record, recordIndex) => ({
              onClick: event => { 
                console.log(event, 're', record, recordIndex);
                getAllDataRequest(event, record, recordIndex);
              }
            })}
          />
          <ModalAdminClients
            isModalVisible={isModalVisible}
            showModal={showModal}
            handleCancel={handleCancel}
            handleOk={handleOk}
            data={client}
          />
        </Col>
        <Col style={{ margin: '40px 0' }} xs={24} md={6}>
          <Row style={{ margin: '0 0 20px 0' }} gutter={24}>
            <Col xs={24} md={12}>
              Solicitudes
              <h1>{requests.length}</h1>
            </Col>
            <Col xs={24} md={12}>
              <Progress type="circle" width={90} percent={99} />
            </Col>
          </Row>
          <Row style={{ margin: '0 0 20px 0' }} gutter={24}>
            <Col xs={24} md={12}>
              % de atención
              <h1>100%</h1>
            </Col>
            <Col xs={24} md={12}>
              <Progress type="circle" width={90} percent={99} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
