import { Row } from "antd";
import joinUs from "../../assets/aboutCard/join_us.svg";
import requestLoan from "../../assets/aboutCard/request.svg";
import receivesLoan from "../../assets/aboutCard/receives.svg";

import { Container } from "../../components/Container/Container";
import { AboutCard } from "./AboutCard/AboutCard";

import "./About.scss";

export const About = () => {
  const steps = [
    {
      id: 1,
      imageUrl: joinUs,
      imageAlt: "Rear view of modern home with pool",
      steper: "Paso 1",
      title: "Regístrate y elige",
      description: "Podrás escoger entre préstamo o tarjeta de crédito",
    },
    {
      id: 2,
      imageUrl: requestLoan,
      imageAlt: "Rear view of modern home with pool",
      steper: "Paso 2",
      title: "Solicita tu préstamo",
      description: "Completa lo datos y envia la solicitud",
    },
    {
      id: 3,
      imageUrl: receivesLoan,
      imageAlt: "Rear view of modern home with pool",
      steper: "Paso 3",
      title: "Recibe tu préstamo",
      description: "Podrás hacer seguimiento y el banco se contactará contigo",
    },
  ];

  return (
    <section id="about" className="about">
      <Container>
        <h2 className="about-heading">¿Cómo funciona?</h2>
        <Row justify="center" wrap>
          {" "}
          {steps.map((steps) => (
            <AboutCard
              key={steps.id}
              step={steps}
              title={steps.title}
              description={steps.description}
              src={steps.imageUrl}
              alt={steps.imageAlt}
              icon={steps.imageUrl}
              steper={steps.steper}
            />
          ))}
        </Row>
      </Container>
    </section>
  );
};
