import { Card, Form, Row } from 'antd';
import { PropTypes } from 'prop-types';
import { UploadFile } from '../../../components/UploadFile/UploadFile';
import './StepSix.scss';

export const StepSix = ({ typeEmployed, children }) => {
  return (
    <>
      {' '}
      {children}
      <Row>
        {!['Jubilado', 'Independiente'].includes(typeEmployed) && (
          <Card
            style={{
              width: '100%',
            }}
            bordered={false}
            hoverable={false}
          >
            <Form.Item
              colon={false}
              className="step-six_icon"
              name="cedula_link"
              label="Documento de identidad Personal (Cédula)"
            >
              {' '}
              <UploadFile name={'cedula_link'} />
            </Form.Item>

            <Form.Item
              colon={false}
              name="carta_trabajo"
              label="Carta de trabajo"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'carta_trabajo'} />
            </Form.Item>
            <Form.Item
              className="step-six_icon"
              colon={false}
              name="ficha_css"
              label="Ficha de la caja de Seguro Social"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'ficha_css'} />
            </Form.Item>
            <Form.Item
              colon={false}
              className="step-six_icon"
              name="ult_comprobante_pago"
              label="Último comprobante de pago"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'ult_comprobante_pago'} />
            </Form.Item>
            <Form.Item
              colon={false}
              name="ult_recibo_servicios"
              className="step-six_icon"
              label="Último recibo uno (1) de los de servicios: teléfono, electricidad o agua"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'ult_recibo_servicios'} />
            </Form.Item>
            <Form.Item
              colon={false}
              name="carta_saldos"
              label="Cartas de saldos originales y vigentes (en caso de consolidar deudas)"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'carta_saldos'} />
            </Form.Item>
          </Card>
        )}
        {typeEmployed === 'Jubilado' && (
          <Card
            style={{
              width: '100%',
            }}
            hoverable={false}
            bordered={false}
          >
            <Form.Item
              colon={false}
              name="cedula_link"
              className="step-six_icon"
              label="Documento de identidad Personal (Cédula)"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'cedula_link'} />
            </Form.Item>
            <Form.Item
              colon={false}
              className="step-six_icon"
              name="talonario_pago"
              label="Último talonario de pago"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'talonario_pago'} />
            </Form.Item>
            <Form.Item
              colon={false}
              className="step-six_icon"
              name="certificado_jubilacion"
              label="Certificado de jubilación emitido por la Caja de Seguro Social"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'certificado_jubilacion'} />
            </Form.Item>
            <Form.Item
              colon={false}
              name="carnet_css"
              className="step-six_icon"
              label="Carné de seguro social"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'carnet_css'} />
            </Form.Item>
            <Form.Item
              colon={false}
              name="carta_saldos"
              label="Cartas de saldos originales y vigentes (en caso de consolidar deudas)"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'carta_saldos'} />
            </Form.Item>
            <Form.Item
              colon={false}
              name="ult_recibo_servicios"
              className="step-six_icon"
              label="Último recibo uno (1) de los de servicios: teléfono, electricidad o agua"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'ult_recibo_servicios'} />
            </Form.Item>
          </Card>
        )}
        {typeEmployed === 'Independiente' && (
          <Card
            style={{
              width: '100%',
            }}
            hoverable={false}
            bordered={false}
          >
            <Form.Item
              colon={false}
              name="cedula_link"
              className="step-six_icon"
              label="Documento de identidad Personal (Cédula)"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'cedula_link'} />
            </Form.Item>
            <Form.Item
              colon={false}
              className="step-six_icon"
              name="ult_recibo_servicios"
              label="Último recibo uno (1) de los de servicios: teléfono, electricidad o agua"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'ult_recibo_servicios'} />
            </Form.Item>
            <Form.Item
              colon={false}
              className="step-six_icon"
              name="primera_declaracion"
              label="Carga una de últimas declaraciones de renta y paz, y salvo del impuesto sobre la renta"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'primera_declaracion'} />
            </Form.Item>
            <Form.Item
              colon={false}
              name="segunda_declaracion"
              className="step-six_icon"
              label="Carga uno de los estados Financieros de los dos ultimos años"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'segunda_declaracion'} />
            </Form.Item>
            <Form.Item
              colon={false}
              name="carta_saldos"
              label="Cartas de saldos originales y vigentes (en caso de consolidar deudas)"
              style={{
                marginTop: '15px',
                padding: '0px',
              }}
            >
              <UploadFile name={'carta_saldos'} />
            </Form.Item>
          </Card>
        )}
      </Row>
    </>
  );
};

StepSix.propTypes = {
  typeEmployed: PropTypes.object,
  next: PropTypes.string,
  children: PropTypes.element,
};
