import { PropTypes } from 'prop-types';
import { Card, Col } from 'antd';
import { AppIcon } from '../../../components/AppIcon/AppIcon';
import './AboutCard.scss';

export const AboutCard = (props) => {
  const { Meta } = Card;
  const { title, description, icon, steper } = props;

  return (
    <Col xs={24} sm={24} lg={8} xl={8}>
      <Card
        className="about__card"
        cover={
          <>
            <div className="about__card-content_step">
              <div className="about__card-step">{steper}</div>
            </div>
            <Meta className="about__card-meta" title={title} description={description} />
            <AppIcon icon={icon} className="about__card-icon" /></>
        }>
      </Card>
    </Col>
  );
};

AboutCard.propTypes = {
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  imageAlt: PropTypes.string,
  steper: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
