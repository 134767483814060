import { useState } from 'react'
import { Col, Form, Card, InputNumber, Checkbox } from 'antd'
import { AppButton } from '../../../components/AppButton/AppButton'
import moment from 'moment'
import 'moment/locale/es'
import { PropTypes } from 'prop-types'
moment.locale('es')

export const EmpresasPrivadas = ({ length, prestamo, loading }) => {
  const [value, setValue] = useState('')
  const [form] = Form.useForm()

  const onChange = (e) => {
    e.preventDefault()
    console.log(`checked = ${e.target.checked}`)
  }

  return (
    <Card title={prestamo} loading={loading} style={{ height: '90vh' }}>
      <h3>Requerimientos</h3>
      <Form
        form={form}
        labelAlign={'center'}
        labelWrap={'true'}
        autoComplete='off'
        wrapperCol={{ span: 24 }}
        layout={'horizontal'}
      >
        <Form.Item
          label='Antiguedad Laboral (meses)'
          name='jobDate'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            min={1}
            max={99}
            value={value}
            onChange={setValue}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Salario mínimo requerido'
          name='salaryLeast'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='$'
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Edad mínima'
          name='ageLeast'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            min={1}
            max={99}
            value={value}
            onChange={setValue}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Puntaje  mínimo de la APC'
          name='scoreApc'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            min={0}
            max={500}
            value={value}
            onChange={setValue}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Nivel de endeudamiento máximo (global)'
          name='globalDebts'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='%'
            min={0}
            max={100}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Nivel de endeudamiento máximo (consumo)'
          name='consumptionDebts'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='%'
            min={0}
            max={100}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Tipo de pago'
          name='typeDiscount'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <Col>
            <Checkbox.Group
              style={{
                padding: '10px 0'
              }}
            >
              <Col>
                <Checkbox value={'Voluntario'} onChange={onChange}>
                  Pago voluntario
                </Checkbox>
                <br />
                <Checkbox value={'Directo'} onChange={onChange}>
                  Descuento directo
                </Checkbox>
              </Col>
            </Checkbox.Group>
          </Col>
        </Form.Item>

        <br />
        <h3>Variables de oferta Bancaria</h3>
        <Form.Item
          label='Tasa mínima ofrecida'
          name='tasaMinima'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='%'
            min={0}
            max={100}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Tasa máxima ofrecida'
          name='tasaMaxima'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='%'
            min={0}
            max={100}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Plazo máximo en años'
          name='plazoMaximo'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            min={1}
            max={36}
            value={value}
            onChange={setValue}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6 }}>
          <AppButton
            onClick={() => console.log('Processing complete!')}
            htmlType={'submit'}
            className='btn__secondary'
            justify-content='center'
          >
            {length > 0 ? 'Solicitar' : 'Guardar'}
          </AppButton>
        </Form.Item>
      </Form>
    </Card>
  )
}

EmpresasPrivadas.propTypes = {
  prestamo: PropTypes.string,
  loading: PropTypes.boolean,
  form: PropTypes.object,
  length: PropTypes.number
}
