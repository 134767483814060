import { Form, Input, Checkbox, Row, Grid } from "antd";
import { PropTypes } from "prop-types";
import { useState, useEffect } from "react";
import { AppButton } from "../../components/AppButton/AppButton";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setAuth } from "../../store/actions/auth";
import LogIn from "../../assets/login/login.svg";
import "./CardLogin.scss";
import { setAnalytics } from "../../services/setAnalytics";
import { getAgentDataByCedula } from "../../services/banks";
const { useBreakpoint } = Grid;

const CardLogin = ({ setVisible, login, getCedula }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const screens = useBreakpoint();
  const [formLogin] = Form.useForm();
  const [, forceUpdate] = useState({});

  console.log(location);
  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = async () => {
    setError("");
    setLoading(true);
    const form = await formLogin.getFieldsValue(true);
    try {
      const resp = await login({
        username: form.email,
        password: form.password,
      });
      setVisible(false);
      const respCedula = await getCedula(resp.access_token);
      sessionStorage.setItem("token", resp.access_token);
      dispatch(
        setAuth(resp.access_token, { cedula: respCedula.cedula_usuario || respCedula.cedula_agente, email: form.email, requestByCalculator: false })
      );
      console.log('AGENTEEEEEEEEEEEEEEE', resp)
      await detectedUser(respCedula.cedula_agente, resp.access_token);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError("Usuario o contraseña inválidos");
    }
  };

  const detectedUser = async (cedula, token) => {
    console.log('TOKEN AGENTE', token)
    if (location.pathname === '/') return navigate("/mis-solicitudes");
    else {
      const dataAgent = await getAgentDataByCedula(cedula);
      console.log('dataAgent', dataAgent);
      sessionStorage.setItem("bank", dataAgent.banco);
      sessionStorage.setItem("token", token);
      dispatch(
        setAuth(token, { cedula, email: dataAgent.correo, requestByCalculator: false, ...dataAgent })
      );
      console.log(dataAgent)
      return navigate("/admin/inicio");
    }

  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="login__box">
     { screens['lg'] === true && <div className="login__img">
        <img src={LogIn} alt="Login" />
      </div>}
      <Form
        form={formLogin}
        name="login__form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <p className="form__title">Bienvenido</p>
        <p className="form__subtitle">Inicia Sesión</p>
        <Form.Item
          name="email"
          hasFeedback
          autoComplete="email"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa tu correo electrónico.",
            },
            {
              type: "email",
              message: "Por favor, ingresa un correo electrónico.",
            },
          ]}
        >
          <Input
            type='email'
            autoComplete="email"
            name="email"
            placeholder="Correo electrónico"
          //onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          hasFeedback
          rules={[
            { required: true, message: "Por favor, ingresa tu contraseña." },
          ]}
        >
          <Input.Password
            name="password"
            placeholder="Contraseña"
          // onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
        {error && <small>{error}</small>}
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Recuerdame</Checkbox>
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Row justify="center">
              <AppButton
                className="btn__primary"
                loading={loading}
                disabled={
                  !formLogin.isFieldsTouched(["email"] && ["password"]) ||
                  !!formLogin
                    .getFieldsError()
                    .filter(({ errors }) => errors.length).length
                }
                action={async () =>
                  setAnalytics("modal_login", "Btn_ModalLogin_Ingresar")
                }
              >
                {loading ? "Cargando" : "Ingresar"}
              </AppButton>
            </Row>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

CardLogin.propTypes = {
  setVisible: PropTypes.func,
  login: PropTypes.func,
  getCedula: PropTypes.func,
};

export default CardLogin;
