import { Row, Col } from "antd";
import "./Home.scss";
import { Container } from "../../components/Container/Container";
import { CardCalculator } from "./CardCalculator/CardCalculator";

export const Home = () => {
  return (
    <section id="home" className="home">
      <Container className="home__container">
        <Row className="home__row">
          <Col xs={24} md={24} lg={12} xl={12} xxl={14} className="home__intro">
            <h1 className="intro__heading">
              Te ayudamos a financiar tus sueños
            </h1>
            <p className="intro__sub-heading">
              ¡Te permitimos comparar y aplicar a diferentes ofertas de
              préstamos en múltiples entidades financieras en una sola
              solicitud!
            </p>
          </Col>
          <Col
            xs={24}
            md={24}
            lg={12}
            xl={12}
            xxl={10}
            className="home__img-wrapper"
          >
            <CardCalculator />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
