import { Card, Form, Input, Row, message } from 'antd';
import { PropTypes } from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppButton } from '../../../components/AppButton/AppButton';
import { Spinner } from '../../../components/Spinner/Spinner';
import { createFinancialData } from '../../../services/works';
import { setWorks } from '../../../store/actions/works';
import './StepFour.scss';

export const StepFour = ({ typeEmployed, next }) => {
  const [form] = Form.useForm();
  const { user, token } = useSelector((store) => store.auth);
  const { laborales, financials } = useSelector((store) => store.works);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const dispatch = useDispatch();

  const validateForm = () => {
    form
      .validateFields()
      .then(() => {
        setIsFormComplete(true);
      })
      .catch(() => {
        setIsFormComplete(false);
      });
  };

  const onChange = (e) => {
    console.log(e);
    validateForm();
    dispatch(
      setWorks(
        laborales,
        { ...financials, [e.target.name]: e.target.value },
        {}
      )
    );
  };
  const saveRequestFinancial = async () => {
    setShowSpinner(true);
    try {
      if (typeEmployed === 'Jubilado') {
        await createFinancialData(
          {
            cedula_usuario: user.cedula,
            ...financials,
            es_independiente: false,
          },
          token
        );
        next();
        setShowSpinner(false);
        return;
      }
      if (typeEmployed === 'Independiente') {
        await createFinancialData({
          token,
          cedula_usuario: user.cedula,
          es_independiente: true,
          ...financials,
        });
        next();
        setShowSpinner(false);
        return;
      }
      await createFinancialData(
        {
          token,
          cedula_usuario: user.cedula,
          es_independiente: true,
          ...financials,
        },
        token
      );
      next();
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      message.error(
        'Ocurrió un error al guardar datos, por favor intenta luego.'
      );
    }
  };

  useEffect(async () => {
    form.setFieldsValue(financials);
    validateForm();
  }, [])
  return (
    <Form
      className="step-four"
      form={form}
      labelAlign={'right'}
      labelWrap={'true'}
      name="form-work"
      layout={'vertical'}
      autoComplete="off"
      initialValues={financials}
    >
      {showSpinner && <Spinner />}
      <Row>
        <Card
          style={{
            width: '100%',
          }}
          className="card-register"
          hoverable={false}
          bordered={false}
        >
          <Form.Item
            colon={false}
            labelAlign="right"
            name="salario_bruto"
            label="Salario bruto mensual"
            rules={[
              {
                required: true,
                message: '',
              },
              () => ({
                validator(_, value) {
                  if (isNaN(value)) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              }),
              () => ({
                validator(_, value) {
                  if (/^[0-9]/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(''));
                },
              }),
            ]}
          >
            <Input
              type="number"
              size="large"
              name="salario_bruto"
              className="card-four_item"
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="cuota_mensual_hipotecas"
            label="Cuota mensual de Hipotecas Residenciales"
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            rules={[
              {
                required: true,
                message: '',
              },
              () => ({
                validator(_, value) {
                  if (isNaN(value)) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="number"
              size="large"
              name="cuota_mensual_hipotecas"
              className="card-four_item"
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            colon={false}
            name="cuota_mensual_auto"
            label="Cuota mensual de Préstamo de Autos"
            rules={[
              {
                required: true,
                message: '',
              },
              () => ({
                validator(_, value) {
                  if (isNaN(value)) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="number"
              size="large"
              name="cuota_mensual_auto"
              className="card-four_item"
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            colon={false}
            name="cuota_mensual_personal"
            label="Cuota mensual de Préstamo personales"
            rules={[
              {
                required: true,
                message: '',
              },
              () => ({
                validator(_, value) {
                  if (isNaN(value)) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="number"
              size="large"
              name="cuota_mensual_personal"
              className="card-four_item"
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            colon={false}
            name="cuota_mensual_otros"
            label="Cuota mensual de otros prestamos, pensiones, mueblerias"
            rules={[
              {
                required: true,
                message: '',
              },
              () => ({
                validator(_, value) {
                  if (isNaN(value)) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="number"
              size="large"
              name="cuota_mensual_otros"
              className="card-four_item"
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            colon={false}
            name="limite_credito"
            label="Limite de crédito aprobado actualmente en Tarjetas de Crédito"
            rules={[
              {
                required: true,
                message: '',
              },
              () => ({
                validator(_, value) {
                  if (isNaN(value)) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="number"
              size="large"
              name="limite_credito"
              className="card-four_item"
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={onChange}
            />
          </Form.Item>
        </Card>
      </Row>
      <Row justify="center" align="center">
        <AppButton
          htmlType={'submit'}
          action={saveRequestFinancial}
          className="btn__secondary"
          disabled={!isFormComplete}
        >
          {'Siguiente'}
        </AppButton>
      </Row>
    </Form>
  );
};

StepFour.propTypes = {
  typeEmployed: PropTypes.object,
  next: PropTypes.string,
};
