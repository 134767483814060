import { PropTypes } from "prop-types";
import "./AppIcon.scss";

export const AppIcon = (props) => {
  const { icon, className = "" } = props;
  return <img className={`app-icon ${className}`} src={icon} />;
};

AppIcon.propTypes = {
  icon: PropTypes.object,
  className: PropTypes.string,
};
