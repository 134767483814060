import { types } from '../types/types';

const initialState = {
  token: '',
  user: {
    cedula: '',  email: '', requestByCalculator: false
  },

}
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };
    case types.logout:
      return {};
    default:
      return state;
  }
};
