import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCa0G6K3QbOpznx6ye5ev1cmLmgfWX7CaE",
  authDomain: "panalytics-frontend.firebaseapp.com",
  projectId: "panalytics-frontend",
  storageBucket: "panalytics-frontend.appspot.com",
  messagingSenderId: "968658049405",
  appId: "1:968658049405:web:f5ae538f5ae37ea5f2d414",
  measurementId: "G-XDG9F2BKL5"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
export { storage };
