import { Home } from "../../containers/Home/Home";
import { About } from "../../containers/About/About";
import { Products } from "../../containers/Products/Products";
import { Helmet } from "react-helmet";
import "./index.scss";

export const Landing = () => {
  return (
    <>
      <Helmet>
      <title>Bankiao</title>
      <meta name="description"
          content="Somos un grupo de personas del mundo tecnológico y financiero con el firme propósito de brindar oportunidades crediticias para todos y todas. Realizar operaciones financieras como llenar una solicitud de préstamo puede ser tan fácil como navegar por Internet." />
      <meta name="keywords"
        content="Préstamos, Solicitud préstamo, crédito bancario, financiamiento fácil, dinero fácil, Efectivo, Bankiao, Panamá" />
      </Helmet>
      <div className="landing">
        <Home />
        <About />
        <Products />
      </div></>
  );
};
