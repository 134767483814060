import axiosInstance from './auth';

const HOST = process.env.REACT_APP_HOST_PANALYTICS;

const loginBank = async (user) => {
  const data = Object.keys(user)
    .map((key) => `${key}=${encodeURIComponent(user[key])}`)
    .join('&');
  const response = await axiosInstance({
    url: `${HOST}/bancos/login`, headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getCedulaWithTokenBank = async (access_token) => {
  console.log(access_token)
  const response = await axiosInstance({
    url: `${HOST}/bancos/auth/extraer_cedula_desde_token`, headers: { 'Accept': 'application/json' },
    method: 'POST',
    data: {
      access_token,
    },
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}


const getAgentDataByCedula = async (cedula) => {
  const response = await axiosInstance({
    url: `${HOST}/bancos/fetch/datos_agente_por_cedula?cedula=${cedula}`, headers: { 'Accept': 'application/json' },
    method: 'GET',
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getRequestByBankAndAgent = async (token) => {
  const response = await axiosInstance({
    url: `${HOST}/bancos/fetch/solicitudes_usuarios?token=${token}`,
    headers: { 'Accept': 'application/json', 'credentials': `${token}` },
  
    method: 'GET',
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const createPoliticsSalariedStaff = async (data) => {
  const response = await axiosInstance({
    url: `${HOST}/bancos/crear/politica_personal_asalariado`, headers: { 'Accept': 'application/json' },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const createPoliticsRetiredtaff = async (data) => {
  const response = await axiosInstance({
    url: `${HOST}/bancos/crear/politica_personal_jubilado`, headers: { 'Accept': 'application/json' },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getPoliciesByBank = async (bank) => {
  const response = await axiosInstance({
    url: `${HOST}/bancos/fetch/politicas_prestamos_personales/?nombre_banco=${bank || sessionStorage.getItem("bank") }`, headers: { 'Accept': 'application/json' },
    method: 'GET',
  });
  console.log('API POLI', response);
  if (response.status === 200) {
    return response.data;
  }
}



export {
  loginBank,
  getCedulaWithTokenBank,
  createPoliticsSalariedStaff,
  getAgentDataByCedula,
  getPoliciesByBank,
  createPoliticsRetiredtaff,
  getRequestByBankAndAgent
};