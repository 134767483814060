import { Navigate } from 'react-router-dom'
import LandingLayout from '../layouts/LandingLayout'
import { Landing } from '../pages/Landing'
import { RegisterAccount } from '../pages/RegisterAccount'
import { RegisterWork } from '../pages/RegisterWork'
import { Requests } from '../pages/Requests'

import AdminLayout from '../layouts/AdminLayout'
import { AdminHome } from '../pages/AdminHome'
import { AdminForm } from '../pages/AdminForm'
import { LoginAdmin } from '../pages/LoginAdmin'

const routes = (token) => [
  {
    path: '/',
    element: <LandingLayout />,
    children: [
      { path: '/', element: <Landing /> },
      { path: 'registro', element: <RegisterAccount /> },
      {
        path: 'datos-laborales',
        element: token ? <RegisterWork /> : <Navigate to='/' />
      },
      {
        path: 'mis-solicitudes',
        element: token ? <Requests /> : <Navigate to='/' />
      }
      /*   { path: '/registro', element: <SignUp /> },
      { path: '/registro-solicitud', element: <Survey /> },
      { path: '*', element: <Navigate to="/404" /> }, */
    ]
  },
  {
    path: 'admin/',
    element: <LoginAdmin />
  },
  {
    path: 'admin/',
    element: <AdminLayout />,
    children: [
      { path: 'inicio', element: token ? <AdminHome /> : <Navigate to='/'/> },
      { path: 'formulario', element: token ? <AdminForm /> : <Navigate to='/'/> }
    ]
  }
]
export default routes
