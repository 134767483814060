import axiosInstance from './auth';

const HOST = process.env.REACT_APP_HOST_PANALYTICS;

const getQuota = async (params) => {
  const response = await axiosInstance({
    url: `${HOST}/calculadora/prestamos`, headers: { 'Accept': 'application/json' },
    method: 'GET',
    params,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

export {
  getQuota
};