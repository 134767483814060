import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { setWorks } from '../../store/actions/works';
import { storage } from '../../services/firebase/config';
import "./UploadFile.scss";

export const UploadFile = ({ name }) => {
  const { user } = useSelector((store) => store.auth);
  const { laborales, documents, financials } = useSelector((store) => store.works);
  const dispatch = useDispatch();

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      message.loading('Cargando archivo');
      return;
    }
    if (info.file.status === 'done') {
      message.info('Archivo cargado exitosamente!');
    }
  };

  const beforeUpload = (file) => {
   // You can remove this validation if you want
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('El archivo debe pesar menos de 5MB!');
    }
    return isLt5M;
  };

  const customUpload = async ({ onError, onSuccess, file }) => {
    console.log('FILE', file)
    const metadata = {
      contentType: file.type
    }
    const imgFile = storage.ref().child(`${user.cedula}/${name}`);
    try {
      const image = await imgFile.put(file, metadata);
      const url = await image.ref.getDownloadURL();
      dispatch(setWorks(laborales, financials, {
        ...documents, [name]: url || 'No registrado'
      }));
      console.log('IMAGE', url);
      onSuccess(null, image);
    } catch (e) {
      console.log('ERROR', e);
      onError(e);
    }
  };
  return (
    <Upload
      name={name}
      className="avatar-uploader"
      beforeUpload={beforeUpload}
      onChange={handleChange}
      customRequest={customUpload}
      maxCount={1}
    >
      <Button style={{
        background: '#00337C',
        color: 'white',
        borderRadius: '8px',
        height: '34px',
        marginTop: '0px',
        paddingTop: '0px',
      }} icon={<UploadOutlined  />}><span>Seleccionar archivo</span></Button>
    </Upload>
  );
};

UploadFile.propTypes = {
  name: PropTypes.string,
};
