import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  message,
} from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../components/Spinner/Spinner";
import { setWorks } from "../../../store/actions/works";
import { AppButton } from "../../../components/AppButton/AppButton";
//import companies from "../../../utils/companies";
import {
  createLaboralData,
  createLaboralIndepentData,
  getUserFinancial,
} from "../../../services/works";
import industries from "../../../utils/industries";
import institutions from "../../../utils/institutions";
import profesions from "../../../utils/profesions";
import "./StepThree.scss";
moment.locale("es");

const { Option } = Select;
const { TextArea } = Input;

const SelectPanalytics = ({ label, name, value, options, onChangeSelect }) => {
  return (
    <Form.Item
      label={label}
      name={name}
      extra={"Si su opción no esta en la lista seleccione 'Otro'"}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Select
        label={label}
        name={name}
        value={value}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
        placeholder="Seleccione"
        onChange={onChangeSelect}
        allowClear
        size="large"
      >
        {options.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectPanalytics.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
};

const FullDatePanalytics = ({ label, name, value, onChangeDate }) => {
  console.log("JJJJJJJJJJJJJJJJ", value);
  return (
    <Form.Item
      label={label}
      name={name}
      valuePropName={"date"}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <DatePicker
        format={"YYYY/MM/DD"}
        name={name}
        value={moment(value) || ""}
        locale={locale}
        style={{ width: "100%" }}
        placeholder={`Ingrese ${label.toLowerCase()}`}
        onChange={onChangeDate}
        size="large"
      />
    </Form.Item>
  );
};

FullDatePanalytics.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export const StepThree = ({ typeEmployed, next }) => {
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { user, token } = useSelector((store) => store.auth);
  const { laborales } = useSelector((store) => store.works);
  const dispatch = useDispatch();

  const validateForm = () => {
    form
      .validateFields()
      .then(() => {
        setIsFormComplete(true);
      })
      .catch(() => {
        setIsFormComplete(false);
      });
  };

  const onChangePlace = (value) => {
    validateForm();
    dispatch(
      setWorks(
        {
          ...laborales,
          lugar_trabajo: value,
        },
        {},
        {}
      )
    );
  };
  const onChangeProfession = (value) => {
    validateForm();
    dispatch(
      setWorks(
        {
          ...laborales,
          profesion: value,
        },
        {},
        {}
      )
    );
  };
  const onChangeIndustry = (value) => {
    validateForm();
    dispatch(
      setWorks(
        {
          ...laborales,
          industria: value,
        },
        {},
        {}
      )
    );
  };
  const onChange = (e) => {
    validateForm();
    dispatch(
      setWorks({
        ...laborales,
        [e.target.name]: e.target.value,
      })
    );
  };
  const onChangeDate = (dateString) => {
    validateForm();
    console.log(moment(dateString).format("YYYY/MM/DD"));
    const date = moment(dateString).format("YYYY/MM/DD");
    dispatch(
      setWorks(
        {
          ...laborales,
          fecha_inicio_empleo: date,
        },
        {},
        {}
      )
    );
  };
  const onChangeDateIndependiente = (dateString) => {
    validateForm();
    console.log(moment(dateString).format("YYYY/MM/DD"));
    const date = moment(dateString).format("YYYY/MM/DD");
    dispatch(
      setWorks(
        {
          ...laborales,
          fecha_inicio_operaciones: date,
        },
        {},
        {}
      )
    );
  };

  const saveRequestLaboral = async () => {
    console.log("LABORALES SAVE", laborales);
    console.log("TOKEN", token, typeEmployed);
    setShowSpinner(true);
    try {
      if (typeEmployed === "Independiente") {
        await createLaboralIndepentData(
          {
            token,
            cedula_usuario: user.cedula,
            ...laborales,
          },
          token
        );
        setShowSpinner(false);
        next();
        return;
      }
      await createLaboralData(
        {
          token,
          tipo_empleo: typeEmployed,
          cedula_usuario: user.cedula,
          ...laborales,
        },
        token
      );
      console.log("exec");
      setShowSpinner(false);
      next();
    } catch (error) {
      console.log("errt", error);
      setShowSpinner(false);
      message.error(
        "Ocurrió un error al guardar datos laborales, por favor intenta luego."
      );
    }
  };

  useEffect(async () => {
    try {
      const financialData = await getUserFinancial(user.cedula, token);
      dispatch(setWorks(laborales, financialData, {}));
      console.log("Financials", financialData);
      form.setFieldsValue(laborales);
      validateForm();
    } catch (error) {
      console.log("No tiene datos financieros");
    }
  }, []);

  return (
    <Form
      className="step-three"
      form={form}
      labelAlign={"right"}
      labelWrap={"true"}
      name="form-work"
      layout={"vertical"}
      autoComplete="off"
      initialValues={laborales}
    >
      {showSpinner && <Spinner />}
      <Row>
        {["Administrador de Gobierno", "Especialista de Gobierno"].includes(
          typeEmployed
        ) && (
          <Card
            style={{
              width: "100%",
            }}
            className="card-register"
            hoverable={false}
            bordered={false}
          >
            <FullDatePanalytics
              label={"Fecha de inicio de Trabajo"}
              name="fecha_inicio_empleo"
              onChangeDate={onChangeDate}
            />

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {" "}
              <Form.Item
                label="Tipo de contrato"
                name="tipo_contrato"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Radio.Group
                  name="tipo_contrato"
                  style={{
                    padding: "10px 0",
                  }}
                  onChange={onChange}
                >
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Radio value={"Permanente"}>Permanente</Radio>
                    <Radio value={"Temporal"}>Temporal</Radio>
                  </Col>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Form.Item
              label="¿Tiene posibilidad de descuento directo?"
              className="step-three__card-radio"
              name="disp_desc_directo"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Radio.Group
                name="disp_desc_directo"
                onChange={onChange}
                style={{
                  padding: "10px 0",
                }}
              >
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Col>
              </Radio.Group>
            </Form.Item>
            <SelectPanalytics
              options={institutions}
              label={"Nombre de la entidad gubernamental donde labora"}
              extra="Si la empresa no está en la lista selecciona 'Otra empresa'"
              name="lugar_trabajo"
              onChangeSelect={onChangePlace}
            />
            <SelectPanalytics
              options={profesions}
              label={"Nombre de su profesión"}
              extra="Si la profesión no está en la lista selecciona 'Otra profesión'"
              name="profesion"
              onChangeSelect={onChangeProfession}
            />
          </Card>
        )}
        {typeEmployed === "Independiente" && (
          <Card
            style={{
              width: "100%",
            }}
            className="card-register"
            hoverable={false}
            bordered={false}
          >
            <FullDatePanalytics
              label={"Fecha de inicio de Operaciones"}
              name="fecha_inicio_operaciones"
              onChangeDate={onChangeDateIndependiente}
            />
            <SelectPanalytics
              options={industries}
              label={"Nombre de industria a la que pertenece"}
              name="industria"
              onChangeSelect={onChangeIndustry}
            />
            <Form.Item
              label="Describe brevemente su actividad"
              name="descripcion_actividad"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <TextArea
                size="large"
                name="descripcion_actividad"
                placeholder="Cuéntanos..."
                autoSize={{ minRows: 2, maxRows: 6 }}
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item
              label={"Nombre de su empresa/negocio"}
              name="nombre_empresa"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                name="nombre_empresa"
                placeholder="Empresa o negocio"
                onChange={onChange}
              />
            </Form.Item>

            <Form.Item
              label="N° de aviso de operación"
              name="aviso_operacion"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                type="number"
                size="large"
                name="aviso_operacion"
                placeholder="Ingrese número de aviso de operación"
                extra="Si la profesión no está en la lista selecciona 'Otra profesión'"
                onChange={onChange}
                className="form-work__input"
              />
            </Form.Item>
            <Form.Item label="N° de RUC *si aplica" name="ruc">
              <Input
                type="number"
                size="large"
                name="ruc"
                placeholder="Ingrese número de aviso de RUC"
                onChange={onChange}
                className="form-work__input"
              />
            </Form.Item>
          </Card>
        )}
        {["Empresa Privada/ONGs"].includes(typeEmployed) && (
          <Card
            style={{
              width: "100%",
            }}
            className="card-register"
            hoverable
            bordered={false}
          >
            <FullDatePanalytics
              label={"Fecha de inicio de Trabajo"}
              name="fecha_inicio_empleo"
              onChangeDate={onChangeDate}
            />
            <Form.Item
              label="Tipo de contrato"
              name="tipo_contrato"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Radio.Group
                  name="tipo_contrato"
                  style={{
                    padding: "10px 0",
                  }}
                  onChange={onChange}
                >
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Radio value={"Permanente"}>Permanente</Radio>
                    <Radio value={"Temporal"}>Temporal</Radio>
                  </Col>
                </Radio.Group>
              </Col>
            </Form.Item>
            <Form.Item
              label="¿Tiene posibilidad de descuento directo?"
              className="step-three__card-radio"
              name="disp_desc_directo"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Radio.Group
                name="disp_desc_directo"
                onChange={onChange}
                style={{
                  padding: "10px 0",
                }}
              >
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Col>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={"Nombre de su empresa/negocio"}
              name="lugar_trabajo"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                name="lugar_trabajo"
                placeholder="Empresa o negocio"
                onChange={onChange}
              />
            </Form.Item>
            <SelectPanalytics
              options={profesions}
              label={"Nombre de su profesión"}
              extra="Si la profesión no está en la lista selecciona 'Otra profesión'"
              name="profesion"
              onChangeSelect={onChangeProfession}
            />
          </Card>
        )}
      </Row>
      <Row justify="center" align="center">
        <AppButton
          htmlType={"submit"}
          action={saveRequestLaboral}
          className="btn__secondary"
          disabled={!isFormComplete}
        >
          {"Siguiente"}
        </AppButton>
      </Row>
    </Form>
  );
};

StepThree.propTypes = {
  typeEmployed: PropTypes.string.isRequired,
  next: PropTypes.func,
};
