import { PropTypes } from "prop-types";
import { Modal } from 'antd';
import CardLogin from '../CardLogin/CardLogin';
import { loginUser } from "../../services/user";
import { getCedulaWithTokenUser } from "../../services/user";
import './ModalAuth.scss';

const ModalAuth = ({ setVisible, visible, handleCancelAuth }) => {
  return (
    <>
      <Modal className='modal__container' closable visible={visible} onCancel={handleCancelAuth} footer={null}>
        <CardLogin setVisible={setVisible} login={loginUser} getCedula={getCedulaWithTokenUser} />
      </Modal>
    </>
  );
};

ModalAuth.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  handleCancelAuth: PropTypes.func,
};

export default ModalAuth;