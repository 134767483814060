import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Form, InputNumber, Radio, Card, notification } from "antd";
import moment from "moment";
import "moment/locale/es";
import { PropTypes } from "prop-types";
import { AppButton } from "../../../components/AppButton/AppButton";
import { createPoliticsSalariedStaff } from "../../../services/banks";
moment.locale("es");

export function AsalariadosGobierno({
  prestamo,
  loading,
  policies,
  getPolicies,
}) {
  const [api, contextHolder] = notification.useNotification();
  const { user, token } = useSelector((store) => store.auth);
  const [form] = Form.useForm();
  console.log(user);
  const saveDataSalaried = async (values) => {
    console.log("VALUES SALARIED", values);
    await createPoliticsSalariedStaff({
      token,
      nombre_banco: user.banco || sessionStorage.getItem("bank"),
      ...values,
    });
    openNotificationWithIcon("success");
    await getPolicies();
  };

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Información",
      description: "Guardado con exito",
    });
  };
  useEffect(() => {
    console.log("POLI", policies);
    form.setFieldsValue({
      ...policies,
    });
  }, []);

  return (
    <Card title={prestamo} loading={loading}>
      <Form
        form={form}
        autoComplete="off"
        layout={"vertical"}
        onFinish={saveDataSalaried}
      >
        <h4>Requerimientos</h4>
        <Form.Item
          label="Antiguedad Laboral (meses)"
          name="antiguedad_laboral"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            min={1}
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Salario mínimo requerido"
          name="salario_minimo"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            addonAfter="$"
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Edad mínima"
          name="edad"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            min={1}
            max={99}
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Puntaje  mínimo de la APC"
          name="puntaje_apc"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            min={0}
            max={500}
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Endeudamiento max. (global)"
          name="nivel_endeudamiento_global"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            addonAfter="%"
            min={0}
            max={100}
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Endeudamiento máx. (consumo)"
          name="nivel_endeudamiento_consumo"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            addonAfter="%"
            min={0}
            max={100}
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Tipo de pago"
          name="tipo_pago"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <Col>
            {
              <Radio.Group
                style={{
                  padding: "10px 0",
                }}
                defaultValue={
                  policies &&
                  Object.values(policies).length !== 0 &&
                  policies["tipo_pago"]
                }
              >
                <Radio value={"Voluntario"}>Voluntario</Radio>
                <Radio value={"Directo"}>Directo</Radio>
              </Radio.Group>
            }
          </Col>
        </Form.Item>
        <h4>Variables de oferta Bancaria</h4>
        <Form.Item
          label="Tasa mínima ofrecida"
          name="tasa_minima"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            addonAfter="%"
            min={0}
            max={100}
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Tasa máxima ofrecida"
          name="tasa_maxima"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            addonAfter="%"
            min={0}
            max={100}
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label="Plazo máximo en años"
          name="plazo"
          rules={[
            {
              required: true,
              message: "Campo obligatorio",
            },
          ]}
        >
          <InputNumber
            min={1}
            max={36}
            style={{
              width: "100%",
            }}
          ></InputNumber>
        </Form.Item>
        <AppButton
          htmlType={"submit"}
          className="btn__secondary"
          justify-content="center"
        >
          {"Guardar"}
        </AppButton>
      </Form>
      {contextHolder}
    </Card>
  );
}

AsalariadosGobierno.propTypes = {
  policies: PropTypes.object,
  prestamo: PropTypes.string,
  loading: PropTypes.boolean,
  form: PropTypes.object,
  getPolicies: PropTypes.function,
};
