import { Register } from '../../containers/Register/Register';

export const RegisterAccount = () => {

  return (
    <>
      <Register />
    </>
  );
};
