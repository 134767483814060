import { Col, Row, Table, Space } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Clients from "../../assets/request/request.svg";
import { getRequestByCedula } from "../../services/request";
import "./Request.scss";

const columns = [
  {
    title: "Fecha de solicitud",
    dataIndex: "fecha_solicitud",
    key: "fecha_solicitud",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Tipo de solicitud",
    dataIndex: "tipo_solicitud",
    key: "tipo_solicitud",
  },
  {
    title: "Motivo de solicitud",
    dataIndex: "subtipo_solicitud",
    key: "subtipo_solicitud",
  },
  {
    title: "Estado de solicitud",
    dataIndex: "estado_solicitud",
    key: "estado_solicitud",
  },
  {
    title: "Acciones",
    key: "action",
    render: () => (
      <Space size="middle">
        <Link
          to={"/datos-laborales"}
          state={{
            request: true,
          }}
        >
          ver solicitud
        </Link>
      </Space>
    ),
  },
];

export const Request = () => {
  const { user, token } = useSelector((store) => store.auth);
  console.log(user);
  const [requestsMe, setrequestsMe] = useState([]);
  const getRequestByUser = async () => {
    try {
      if (user.cedula) {
        const requestsByUser = await getRequestByCedula(user.cedula, token);
        if (requestsByUser.length != 0) {
          setrequestsMe(requestsByUser.prestamos);
        }
      }
    } catch (error) {
      console.log("Usuario no tiene solicitudes");
    }
  };
  useEffect(() => {
    getRequestByUser();
  }, []);

  return (
    <Col className="request">
      <Row justify="center" align="center" className="request__image">
        <img src={Clients} alt="Login" />
      </Row>
      <Row justify="center" align="center">
        <h1 className="request__title">Solicitudes Pendientes</h1>
      </Row>
      <Row className="request__table" justify="center" align="center">
        <Table columns={columns} dataSource={requestsMe} sticky={true} />
      </Row>
    </Col>
  );
};
