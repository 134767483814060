import { Row, Col } from "antd";
import register from "../../assets/register/form.svg";
import "./Register.scss";
import { CardRegister } from "./CardRegister/CardRegister";
import { useState } from "react";
import { Spinner } from "../../components/Spinner/Spinner";

export const Register = () => {
  const [showTitle, setShowTitle] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  return (
    <>
      {showSpinner ? (
        <Row className="spinner__center" justify="center" align="center">
          <Spinner />
        </Row>
      ) : (
        <Row className="register">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row className="register__container-title">
              <Col xs={24} sm={24} md={24} lg={12} xl={24}>
                <img
                  className="register__img"
                  src={register}
                  alt="varon llenando un formulario (men fill a form)"
                />
                <h1 className="register__title">
                  {showTitle ? "Crea tu cuenta" : "Conozcámonos un poco más"}
                </h1>
                <h4>Accede facilmente a un producto financiero</h4>
              </Col>
            </Row>
            <Row className="register__container-title">
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 24, offset: 0 }}
                md={20}
                lg={20}
                xl={16}
              >
                <CardRegister
                  setShowTitle={setShowTitle}
                  setShowSpinner={setShowSpinner}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
