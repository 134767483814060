const axiosInstance = require("axios");

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("call the refresh token api here");
      window.location.reload(true);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
