import { LeftOutlined } from "@ant-design/icons";
import { Button, Form, message, Row, Steps } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import register from "../../assets/register/form.svg";
import { AppButton } from "../../components/AppButton/AppButton";
import { Container } from "../../components/Container/Container";
import { Spinner } from "../../components/Spinner/Spinner";
import {
  createDocumentsData,
  createDocumentsIndepentData,
  createDocumentsRetiredData,
} from "../../services/works";
import { StepOne } from "./StepOne/StepOne";
import { StepTwo } from "./StepTwo/StepTwo";
import { StepThree } from "./StepThree/StepThree";
import { StepFour } from "./StepFour/StepFour";
import { StepFive } from "./StepFive/StepFive";
import { StepSix } from "./StepSix/StepSix";

import "./WorkSteps.scss";

const { Step } = Steps;

export const WorkSteps = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [typeEmployed, setTypeEmployed] = useState("");
  console.log("LOCATION", location);
  const { documents } = useSelector((store) => store.works);
  const { user, token } = useSelector((store) => store.auth);

  const saveRequest = async () => {
    try {
      setShowSpinner(true);
      if (typeEmployed === "Jubilado") {
        if (Object.values(documents).length != 0) {
          await createDocumentsRetiredData(
            {
              token,
              cedula_usuario: user.cedula,
              cedula_link: documents.cedula_link,
              talonario_pago: documents.talonario_pago,
              certificado_jubilacion: documents.certificado_jubilacion,
              carnet_css: documents.carnet_css,
              ult_recibo_servicios: documents.ult_recibo_servicios,
              carta_saldos: documents.carta_saldos || "No registrado",
            },
            token
          );
        }
        setTimeout(() => {
          message.info("Datos registrados correctamente");
          setShowSpinner(true);
          navigate("/mis-solicitudes");
        }, 2000);
        return;
      }
      if (typeEmployed === "Independiente") {
        if (Object.values(documents).length != 0) {
          await createDocumentsIndepentData({
            token,
            cedula_usuario: user.cedula,
            cedula_link: documents.cedula_link,
            primera_declaracion: documents.primera_declaracion,
            segunda_declaracion:
              documents.segunda_declaracion || "No registrado",
            ult_recibo_servicios: documents.ult_recibo_servicios,
            carta_saldos: documents.carta_saldos || "No registrado",
          });
          setTimeout(() => {
            message.info("Datos registrados correctamente");
            setShowSpinner(true);
            navigate("/mis-solicitudes");
          }, 2000);
          return;
        }
      }
      console.log("DOCUMENS", documents);
      if (Object.values(documents).length != 0) {
        await createDocumentsData(
          {
            token,
            cedula_usuario: user.cedula,
            cedula_link: documents.cedula_link,
            carta_trabajo: documents.carta_trabajo || "No registrado",
            ficha_css: documents.ficha_css,
            ult_comprobante_pago: documents.ult_comprobante_pago,
            ult_recibo_servicios: documents.ult_recibo_servicios,
            carta_saldos: documents.carta_saldos || "No registrado",
          },
          token
        );
      }

      setTimeout(() => {
        message.info("Datos registrados correctamente");
        setShowSpinner(true);
        navigate("/mis-solicitudes");
      }, 2000);
    } catch (error) {
      setShowSpinner(false);
      message.error(
        "Ocurrió un error al generar la solicitud, por favor intenta luego." +
          error
      );
    }
  };

  const next = () => {
    form
      .validateFields()
      .then((e) => {
        console.log("VALID", e);
        if (typeEmployed === "Jubilado") {
          if (current === 1) {
            setCurrent(current + 2);
            return;
          }
          setCurrent(current + 1);
        }
        setCurrent(current + 1);
      })
      .catch((err) => console.log(err));
  };

  const prev = () => {
    console.log("CURRENT", current);
    if (typeEmployed === "Jubilado") {
      if (current === 3) {
        setCurrent(current - 2);
        return;
      }
      setCurrent(current - 1);
    }
    setCurrent(current - 1);
  };
  const steps = [
    {
      title: "Cuéntanos más sobre tu préstamo",
      subtitle: "¿Qué harás con el dinero?",
      content: <StepOne next={next} />,
    },
    {
      title: "Datos Laborales",
      subtitle: "Y accede fácilmente a un producto financiero",
      content: <StepTwo setTypeEmployed={setTypeEmployed} next={next} />,
    },
    {
      title: "Detalles del empleo actual",
      content: <StepThree typeEmployed={typeEmployed} next={next} />,
    },
    {
      title: "Datos Financieros",
      subtitle: "Ahora analicemos tus finanzas",
      content: <StepFour typeEmployed={typeEmployed} next={next} />,
    },
    {
      title: "Tenemos algo para ti",
      content: <StepFive typeEmployed={typeEmployed} next={next} />,
    },
    {
      title: "Documentación",
      subtitle:
        "Para completar tu solicitud  es importante subir los siguientes documentos.",
      content: (
        <StepSix typeEmployed={typeEmployed} next={next}>
          <span className="children">
            Si no cuentas con los documentos ahora, puedes cargarlos mas tarde
          </span>
        </StepSix>
      ),
    },
  ];

  return (
    <>
      {showSpinner ? (
        <Row className="spinner__center" justify="center" align="center">
          <Spinner />
        </Row>
      ) : (
        <Container className="work-steps">
          <Row className="step-content__image" justify="center">
            <img
              src={register}
              alt="varon llenando un formulario (men fill a form)"
            />
          </Row>

          <Row className="step-content__steps" justify="center">
            <Steps className="step__steps" responsive={1} current={current}>
              {steps.map((item) => (
                <Step key={item.title} />
              ))}
            </Steps>
          </Row>
          {current > 0 && (
            <Button
              color="primary"
              type="text"
              style={{ margin: "0 8px" }}
              onClick={() => prev()}
            >
              <LeftOutlined /> volver
            </Button>
          )}
          <Row className="step-content__header" justify="center">
            <h1>{steps[current].title}</h1>
            <h4>{steps[current].subtitle}</h4>
          </Row>

          <Row className="step-content__content">{steps[current].content}</Row>
          <Row className="step-content__content">
            {current === steps.length - 1 && (
              <AppButton
                htmlType={"submit"}
                loading={showSpinner}
                action={() => saveRequest()}
                className="btn__primary"
              >
                {showSpinner ? "Registrando" : "Registrar mi solicitud"}
              </AppButton>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};
