import { Spin, Row } from 'antd';
import "./Spinner.scss";

export const Spinner = () => {
  return (
     <Row className="spinner" >
      <Spin tip="Cargando" />
    </Row>
  );
};

