import { types } from '../types/types';

const initialState = {
  laborales: {},
  financials: {},
  documents: {}
}
export const worksReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.works:
      return {
        ...state,
        laborales:
          action.payload.laborales,
        financials: action.payload.financials,
        documents: action.payload.documents,
      };
    default:
      return state;
  }
};
