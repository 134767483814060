import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { Row, Col, Select } from 'antd'
import '../../containers/WorkSteps/WorkSteps.scss'
import { Container } from '../../components/Container/Container'
import { EmpresasPrivadas } from '../../containers/FormsAdmin/EmpresasPrivadas/EmpresasPrivadas'
import { AsalariadosGobierno } from '../../containers/FormsAdmin/AsalariadosGobierno/AsalariadosGobierno'
import NoneForm from '../../containers/FormsAdmin/noneForm/NoneForm'
import { Independientes } from '../../containers/FormsAdmin/Independientes/Independientes'
import { JubiladosPensionados } from '../../containers/FormsAdmin/Jubilados y Pensionados/JubiladosPensionados'
import { getPoliciesByBank } from "../../services/banks";
import "./index.scss";

const oferta = [
 /*  {
    label: 'Asalariados de Empresas Privadas',
    value: 'Asalariados de Empresas Privadas'
  }, */
  { label: 'Asalariados del Gobierno', value: 'Asalariados del Gobierno' },
// { label: 'Independientes', value: 'Independientes' },
  { label: 'Jubilados y Pensionados', value: 'Jubilados y Pensionados' }
]

export function AdminForm() {
  const [prestamo, setPrestamo] = useState('')
  const [loading, setLoading] = useState(false)
  const [dataPolicie, setDataPolicie] = useState({});
  const { user } = useSelector((store) => store.auth);

  console.log(prestamo.search)
  const handleSelectChange = (value) => {
    console.log(value)
    setPrestamo(value)
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }
  async function getPolicies(bank) {
    let policies = await getPoliciesByBank(bank);
    policies = policies.map((policie) => {
      return {
        [policie[0]]: policie[1]
      }
    })

    policies = policies.reduce((acumulador, objeto) => {
      return { ...acumulador, ...objeto };
    }, {});
    console.log('POLICIES', policies)
    setDataPolicie(policies);

  }
  console.log('====>', prestamo)
  useEffect(() => {
    getPolicies(user.banco);
  }, [])
  
  return (
    <Container className='container'>
      <Row gutter={[16]} className>
        <Col span={12}>
          <h2>Tipo de préstamo</h2>
          <p>Seleccionado:{prestamo}</p>
          <Select
            onChange={handleSelectChange}
            options={oferta}
            placeholder='Seleccionar'
            style={{ width: '50%' }}
          />
        </Col>

        <Col className='form-policies' span={10} offset={2}>
          {(() => {
            if (prestamo === 'Asalariados de Empresas Privadas') {
              return <EmpresasPrivadas prestamo={prestamo} loading={loading} policies={dataPolicie['Asalariados']} getPolicies={getPolicies}/>
            } else if (prestamo === 'Asalariados del Gobierno') {
              return (
                <AsalariadosGobierno prestamo={prestamo} loading={loading} policies={dataPolicie['Asalariados']} getPolicies={getPolicies} />
              )
            } else if (prestamo === 'Independientes') {
              return <Independientes prestamo={prestamo} loading={loading} policies={dataPolicie['Asalariados']} getPolicies={getPolicies} />
            } else if (prestamo === 'Jubilados y Pensionados') {
              return (
                <JubiladosPensionados prestamo={prestamo} loading={loading} policies={dataPolicie['Jubilados']} getPolicies={getPolicies} />
              )
            } else {
              return <NoneForm />
            }
          })()}
        </Col>
      </Row>
    </Container>
  )
}
