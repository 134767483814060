import { useEffect, useState } from "react";
import { Col, Row, Radio, Card, Form } from "antd";
import { PropTypes } from "prop-types";
import { AppButton } from "../../../components/AppButton/AppButton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setRequest } from "../../../store/actions/request";
import { getRequestByCedula } from "../../../services/request";
import one from "../../../assets/steps/step-one/stepone-1.png";
import two from "../../../assets/steps/step-one/stepone-2.png";
import three from "../../../assets/steps/step-one/stepone-3.png";
import four from "../../../assets/steps/step-one/stepone-4.png";
import "./StepOne.scss";

const typesOfLoans = [
  { icon: one, title: "Abrir un negocio" },
  { icon: two, title: "Hacer crecer mi negocio" },
  { icon: three, title: "Comprar algo" },
  { icon: four, title: "Pagar gastos de salud" },
  { icon: one, title: "Otros" },
];
export const StepOne = ({ next }) => {
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { request } = useSelector((store) => store.request);
  const { user, token } = useSelector((store) => store.auth);

  const validateForm = () => {
    form
      .validateFields()
      .then(() => {
        setIsFormComplete(true);
      })
      .catch(() => {
        setIsFormComplete(false);
      });
  };

  const onChange = (e) => {
    validateForm();
    form.setFieldsValue("loanReason", e.target.value);
    dispatch(
      setRequest({
        ...request,
        cedula_usuario: user.cedula,
        subtipo_solicitud: e.target.value,
      })
    );
  };

  const getRequestData = async () => {
    try {
      const data = await getRequestByCedula(user.cedula, token);
      console.log("Solicitudes por cédula", data.prestamos[0]);
      dispatch(
        setRequest({
          ...data.prestamos[0],
        })
      );
      console.log(
        "Solicitudes por cédula",
        data.prestamos[0].subtipo_solicitud
      );

      form.setFieldsValue("loanReason", data.prestamos[0].subtipo_solicitud);
      console.log("jj", request);
      console.log("boli");
    } catch (error) {
      console.log("Usuario no tiene solicitudes");
    }
  };

  useEffect(() => {
    console.log("AQUI LOCATION STATE");
    if (location) {
      console.log("AQUI LOCATION STATE");
      getRequestData();
      validateForm();
    }
  }, []);
  return (
    <Form
      form={form}
      labelAlign={"right"}
      labelWrap={"true"}
      wrapperCol={{ span: 24 }}
      name="form-work"
      layout={"vertical"}
      initialValues={request}
    >
      <Row className="step-one__container">
        <Form.Item name="subtipo_solicitud">
          <Radio.Group className="step-one__group" onChange={onChange}>
            {typesOfLoans.map((loan) => (
              <Card className="step-one__card" key={loan.title}>
                <Row>
                  <Col xs={23} sm={23} md={23} lg={22} xl={22}>
                    {" "}
                    <img
                      className="step-one__img"
                      src={loan.icon}
                      alt="varon llenando un formulario (men fill a form)"
                    />
                    {loan.title}
                  </Col>
                  <Col
                    className="step-one__radio"
                    xs={1}
                    sm={1}
                    md={1}
                    lg={2}
                    xl={2}
                  >
                    {" "}
                    <Radio value={loan.title}></Radio>
                  </Col>
                </Row>
              </Card>
            ))}
          </Radio.Group>
        </Form.Item>
      </Row>
      <Row justify="center" align="center">
        <AppButton
          htmlType={"submit"}
          action={next}
          className="btn__secondary"
          disabled={!isFormComplete}
        >
          {"Siguiente"}
        </AppButton>
      </Row>
    </Form>
  );
};

StepOne.propTypes = {
  typeEmployed: PropTypes.object,
  next: PropTypes.string,
};
