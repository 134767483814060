import { Card } from "antd";
import imgForm from "../../../assets/formAdmin/4944411.jpg";
import "./NoneForm.scss";

const NoneForm = () => {
  return (
    <Card className="none-form">
      <div className="content-form">
        <h2>Selecciona un préstamo para editar</h2>
        <img src={imgForm} className="foto" />
      </div>
    </Card>
  );
};

export default NoneForm;
