import { Affix, Menu } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Header } from "antd/lib/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import { Container } from "../Container/Container";
import { DropdownUser } from "../DropdownUser/DropdownUser";
import ModalAuth from "../ModalAuth/ModalAuth";
import { useLocation, Link } from "react-router-dom";
import "./AppHeader.scss";
import { setAnalytics } from "../../services/setAnalytics";
import logo from "../../assets/header/header_logo.png";

export const AppHeader = () => {
  const [modalAuth, setModalAuth] = useState(false);
  const { token, user } = useSelector((store) => store.auth);
  console.log("sfsfsf", user);
  const location = useLocation();
  // const { Item } = Menu;
  const path = location.pathname;

  const handleCancelAuth = () => {
    setModalAuth(false);
  };
  console.log(location, path);
  const { Item } = Menu;

  return (
    <Affix offsetTop={0}>
      <Header className="app-header">
        <Container className="app-header__content">
          <a className="app-header__logo" href="/">
            <img className="app-header__logo__img" src={logo} alt={"logo"} />
          </a>
          {["/"].includes(path) && (
            <Menu
              className="app-header__menu"
              mode={"horizontal"}
              overflowedIndicator={
                <MenuOutlined className="app-header__menu-icon" />
              }
            >
              {" "}

              <Item key="home">
                <a
                  onClick={() => {
                    setModalAuth(true);
                    setAnalytics("header", "Btn_Header_Login");
                  }}
                >
                  {" "}
                  Iniciar Sesión
                </a>
              </Item>
              <Item className="app-header__menu-register" key="register">
                <Link
                  to={"/registro"}
                  onClick={() => setAnalytics("header", "Btn_Header_Singup")}
                >
                  Regístrate
                </Link>
              </Item>
            </Menu>
          )}
          {token && <DropdownUser user={user.email} />}
        </Container>
      </Header>
      <ModalAuth
        visible={modalAuth}
        setVisible={setModalAuth}
        handleCancelAuth={handleCancelAuth}
      />
    </Affix>
  );
};
