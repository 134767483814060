import axiosInstance from './auth';

const HOST = process.env.REACT_APP_HOST_PANALYTICS;

const createRequestData = async (data) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/crear/nueva_solicitud`, headers: { 'Accept': 'application/json' },
    method: 'POST',
    data,
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getRequestByCedula = async (document, token) => {
  const response = await axiosInstance({
    url: `${HOST}/clientes/fetch/solicitudes_por_cedula?cedula=${document}`,  
    headers: { 'Accept': 'application/json', 'credentials': `${token}` },
    method: 'GET',
  });
  console.log('API', response);
  if (response.status === 200) {
    return response.data;
  }
}

const getAllRequest = async (params, token) => {
  console.log('token API', token)
  const response = await axiosInstance({
    url: `${HOST}/clientes/fetch/todas_las_solicitudes?token=${token || sessionStorage.getItem("token")}`, headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token || sessionStorage.getItem("token")}` },
    method: 'GET',
    params,
  });
  console.log('API REQUESTS', response);
  if (response.status === 200) {
    return response.data;
  }
}



export {
  createRequestData,
  getRequestByCedula,
  getAllRequest
};