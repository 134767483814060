export default [
  "Tecnología de Información y Comunicación",
  "Manufactura",
  "Distribuidores",
  "Energía",
  "Salud",
  "Turismo",
  "Educación",
  "Transporte y Logística",
  "Servicios Legales",
  "Banca, Finanzas y Seguros",
  "Comidas Y Bebidas",
  "Servicios Profesionales",
  "Arquitectura, Construcción, Ingeniería",
  "Consultoría",
  "Sin Fines de Lucro",
  "Bienes Raíces y Construcción",
  "Legislación e Impuestos",
  "Seguridad",
  "Agricultura, Silvicultura, Pesca y Minería",
  "Otros",
  "Gobierno",
  "Medios y Publicidad",
  "Minorista"
]