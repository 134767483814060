import { useState } from 'react'
import { Form, InputNumber, Card } from 'antd'
import moment from 'moment'
import 'moment/locale/es'
import { PropTypes } from 'prop-types'
import { AppButton } from '../../../components/AppButton/AppButton'
moment.locale('es')

export function Independientes({ prestamo, loading }) {
  const [value, setValue] = useState('')
  const [form] = Form.useForm()

  // const onChange = (e) => {
  //   e.preventDefault()
  //   console.log(`checked = ${e.target.checked}`)
  // }

  return (
    <Card title={prestamo} loading={loading} style={{ height: '90vh' }}>
      <Form
        form={form}
        labelAlign={'center'}
        labelWrap={'true'}
        autoComplete='off'
        layout={'horizontal'}
        wrapperCol={{ span: 24 }}
      >
        <h4>Requerimientos</h4>
        <Form.Item
          label='Antigüedad del Negocio (meses)'
          name='companyDate'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            min={1}
            max={99}
            value={value}
            onChange={setValue}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Ingreso Mensual mínimo'
          name='incomeLeast'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='$'
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Edad mínima'
          name='ageLeast'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            min={1}
            max={99}
            value={value}
            onChange={setValue}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Extranjeros'
          name='foreign'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        ></Form.Item>

        <Form.Item
          label='Puntaje  mínimo de la APC'
          name='scoreApc'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            min={0}
            max={500}
            value={value}
            onChange={setValue}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Nivel de endeudamiento máximo (global)'
          name='globalDebts'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='%'
            min={0}
            max={100}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Nivel de endeudamiento máximo (consumo)'
          name='consumptionDebts'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='%'
            min={0}
            max={100}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <br />
        <h4>Variables de oferta Bancaria</h4>
        <Form.Item
          label='Tasa mínima ofrecida'
          name='tasaMinima'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='%'
            min={0}
            max={100}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Tasa máxima ofrecida'
          name='tasaMaxima'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            addonAfter='%'
            min={0}
            max={100}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>

        <Form.Item
          label='Plazo máximo en años'
          name='plazoMaximo'
          rules={[
            {
              required: true,
              message: 'Campo obligatorio'
            }
          ]}
        >
          <InputNumber
            min={1}
            max={36}
            value={value}
            onChange={setValue}
            style={{
              width: '50%'
            }}
          ></InputNumber>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6 }}>
          <AppButton
            onClick={() => console.log('Processing complete!')}
            htmlType={'submit'}
            className='btn__secondary'
            justify-content='center'
          >
            {length > 0 ? 'Solicitar' : 'Guardar'}
          </AppButton>
        </Form.Item>
      </Form>
    </Card>
  )
}

Independientes.propTypes = {
  prestamo: PropTypes.string,
  loading: PropTypes.boolean,
  form: PropTypes.object
}
