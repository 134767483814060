import { Col, Form, Input, Modal, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import "./ModalClients.scss";
//import dayjs from 'dayjs';

export const ModalAdminClients = ({
  isModalVisible,
  handleOk,
  handleCancel,
  data,
}) => {
  const [form] = Form.useForm();
  const [client, setClient] = useState(data);
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  useEffect(() => {
    const dataClient = {
      ...(data.personalData ? data.personalData : {}),
      ...(data.laboralData ? data.laboralData : {}),
      ...(data.financialData ? data.financialData : {}),
      ...(data.requestData ? data.requestData : {}),
      ...(data.documentsData ? data.documentsData : {}),
    };
    console.log("ITEM CLIENT", dataClient);
    setClient(dataClient)
    form.setFieldsValue(client);
  }, [data]);

  return (
    <Modal
      width={1100}
      className="modal"
      title={
        <>
          <Row className="modal__title" justify="space-between">
            <h4>
              {data?.personalData?.nombre} {data?.personalData?.apellido}
            </h4>
            <h4>{data?.personalData?.cedula} </h4>
          </Row>
        </>
      }
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >

      <Row className="modal__section">
        <Col className="modal__col" xl={8}>
          <h3>Perfil</h3>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
              fecha_nacimiento: data.fecha_nacimiento,
              tipo_empleo: data.tipo_empleo,
              correo_electronico: data.correo,
              numero_telefono: data.telefono,
              residence: ["zhejiang", "hangzhou", "xihu"],
            }}
            labelWrap="false"
            layout="vertical"
            scrollToFirstError
          >
            <Form.Item name="fecha_nacimiento" label="Fecha de nacimiento">
              <Input
                name="fecha_nacimiento"
                style={{ width: "100%" }}
                readOnly
              />
            </Form.Item>
            <Form.Item name="telefono" label="Número de teléfono">
              <Input
                name="telefono"
                addonBefore={"+507"}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name="correo" label="Correo electrónico">
              <Input name="correo" />
            </Form.Item>
            <Form.Item name="tipo_empleo" label="Datos laborales">
              <Input />
            </Form.Item>
          </Form>
        </Col>
        <Col className="modal__col" xl={8}>
          <h3>Detalles del empleo actual</h3>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
              fecha_inicio_operaciones:
                data.fecha_inicio_operaciones || "2016/07/07",
              tipo_contrato: data.tipo_contrato,
              disp_desc: data.disp_desc_directo ? "Si" : "No",
              lugar_trabajo: data.lugar_trabajo,
              profesion: data.profesion,
            }}
            layout="vertical"
            scrollToFirstError
          >
            <Form.Item
              name="fecha_inicio_operaciones"
              label="Fecha inicio del trabajo"
            >
              <Input name="fecha_inicio_operaciones" />
            </Form.Item>
            <Form.Item name="tipo_contrato" label="Tipo de contrato">
              <Input />
            </Form.Item>
            <Form.Item name="disp_desc" label="Posibilidad de descuento">
              <Input />
            </Form.Item>
            <Form.Item name="lugar_trabajo" label="Nombre de la empresa">
              <Input />
            </Form.Item>
            <Form.Item name="profesion" label="Profesión">
              <Input />
            </Form.Item>
          </Form>
        </Col>
        <Col className="modal__col" xl={8}>
          <h3>Datos financieros</h3>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
              cuota_mensual_auto: data.cuota_mensual_auto,
              cuota_mensual_hipotecas: data.cuota_mensual_hipotecas,
              cuota_mensual_otros: data.cuota_mensual_otros,
              cuota_mensual_personal: data.cuota_mensual_personal,
              limite_credito: data.limite_credito,
              salario_bruto: data.salario_bruto,
            }}
            layout="vertical"
            scrollToFirstError
          >
            <Form.Item name="salario_bruto" label="Salario bruto mensual">
              <Input />
            </Form.Item>
            <Form.Item
              name="cuota_mensual_hipotecas"
              label="Cuota mensual de hipotecas"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="cuota_mensual_auto"
              label="Cuota mensual de préstamo de auto"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="cuota_mensual_personal"
              label="Cuota mensual de préstamo personal"
            >
              <Input />
            </Form.Item>
            <Form.Item name="cuota_mensual_personal" label="Límite de crédito">
              <Input />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row className="modal__section">
        <Col className="modal__col" xl={16}>
          <h3>Documentación</h3>
          <Form
            form={form}
            name="documuments"
            onFinish={onFinish}
            initialValues={{
              cedula_link: data.cedula_link,
              carta_trabajo: data.carta_trabajo,
              ficha_css: data.ficha_css ? "Si" : "No",
              ult_comprobante_pago: data.ult_comprobante_pago,
              ult_recibo_servicios: data.ult_recibo_servicios,
              carta_saldos: data.carta_saldos,
            }}
            layout="vertical"
            scrollToFirstError
          >

            {client?.cedula_link ? (
              <Col className="modal__col_docs">
                <a
                  href={client?.cedula_link}
                  target={"_blank"}
                  download
                  rel="noreferrer"
                >
                  {" "}
                  Cédula <DownloadOutlined />{" "}
                </a>
                <a
                  href={client?.carta_trabajo}
                  target={"_blank"}
                  download
                  rel="noreferrer"
                >
                  {" "}
                  Carta de trabajo <DownloadOutlined />{" "}
                </a>
                <a
                  href={client?.ficha_css}
                  target={"_blank"}
                  download
                  rel="noreferrer"
                >
                  {" "}
                  Ficha caja de seguro social <DownloadOutlined />{" "}
                </a>
                <a
                  href={client?.ult_comprobante_pago}
                  target={"_blank"}
                  download
                  rel="noreferrer"
                >
                  Último comprobante de pago
                  <DownloadOutlined />{" "}
                </a>
                <a
                  href={client?.ult_recibo_servicios}
                  target={"_blank"}
                  download
                  rel="noreferrer"
                >
                  {" "}
                  Último recibo uno (1) de los de servicios: teléfono,
                  electricidad o agua.
                  <DownloadOutlined />{" "}
                </a>
                {client?.carta_saldos != "No registrado" && (
                  <a
                    href={client?.carta_saldos}
                    target={"_blank"}
                    download
                    rel="noreferrer"
                  >
                    {" "}
                    Cartas de saldos originales y vigentes (en caso de
                    consolidar deudas).
                    <DownloadOutlined />{" "}
                  </a>
                )}
              </Col>
            ) : (
              <small>No se han cargado documentos</small>
            )}
          </Form>
        </Col>
      </Row>
      <Row justify={"end"}>
        <span>Fecha solicitud: {data?.fecha_solicitud}</span>
      </Row>
    </Modal>
  );
};

ModalAdminClients.propTypes = {
  isModalVisible: PropTypes.bool,
  data: PropTypes.object,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  showModal: PropTypes.func,
};
