import { types } from '../types/types';

export const setWorks = (laborales, financials, documents) => ({
  type: types.works,
  payload: {
    laborales,
    financials,
    documents
  },
});
