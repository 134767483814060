import { Col, Row, List } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { Container } from "../Container/Container";
import { useLocation } from "react-router-dom";
import logo from "../../assets/footer/footer_logo.png";
import { Icon } from "@iconify/react";
import "./AppFooter.scss";

export const AppFooter = () => {
  const location = useLocation();
  const path = location.pathname;
  console.log(path);

  return (
    <>
      {path === "/" && (
        <Footer className="app-footer">
          <Container className="app-footer__content">
            <Row className="app-footer__content-row" gutter={24}>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={5}
                xxl={6}
                className="app-footer__logo"
              >
                <a className="app-header__logo">
                  <img className="app-header__img" src={logo} alt={"logo"} />
                </a>
              </Col>
              <Col xs={24} sm={24} md={10} lg={12} xxl={12}>
                <div className="app-footer__subscribe">
                  <h2 className="app-footer__subscribe-title">
                    ¿Quiénes somos?
                  </h2>
                  <p className="app-footer__subscribe-text">
                    Somos un grupo de personas del mundo tecnológico y
                    financiero con el firme propósito de brindar oportunidades
                    crediticias para todos y todas.
                  </p>
                  <p className="app-footer__subscribe-text">
                    Nos apoyamos en el uso de la tecnología para brindar a
                    nuestros clientes una experiencia superior. Estamos
                    convencidos de que realizar operaciones financieras puede
                    ser tan fácil como navegar por Internet.
                  </p>
                </div>
                <div className="app-footer__subscribe">
                  <h2 className="app-footer__subscribe-title">
                    ¿Qué nos caracteriza?
                  </h2>
                  <ul className="app-footer__subscribe-list">
                    <li className="app-footer__subscribe-text">
                      <strong>Innovación: </strong> Ofrecemos a nuestro cliente
                      una plataforma innovadora capaz de ofrecer créditos de
                      manera más ágil y rápida que los mismos bancos.
                    </li>
                    <li className="app-footer__subscribe-text">
                      <strong>Seguridad: </strong>Nos tomamos en serio la
                      seguridad, todos tus datos están seguros con nosotros.
                    </li>
                    <li className="app-footer__subscribe-text">
                      <strong>Experiencia: </strong> Hacemos que tu trayecto en
                      Bankiao sea el mejor posible. Optimizamos nuestro proceso
                      para que logres una experiencia superior.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={7} xxl={5}>
                <div className="app-footer__subscribe">
                  <div>
                    <h2 className="app-footer__subscribe-title">Legal</h2>
                    <List>
                      <li className="app-footer__subscribe-text">
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/panalytics-frontend.appspot.com/o/Condiciones_del_servicio_Bankiao_ES.pdf?alt=media&token=69bc28e9-fb9a-466f-aabe-5604be1d96f8"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Términos y condiciones
                        </a>
                      </li>
                      <li className="app-footer__subscribe-text">
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/panalytics-frontend.appspot.com/o/Aviso_de_Privacidad_Bankiao.pdf?alt=media&token=04db600f-f60f-4e87-b1a0-1e4a8d564ac7"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Política de privacidad de datos{" "}
                        </a>
                      </li>
                    </List>
                  </div>
                  <div>
                    <h2 className="app-footer__subscribe-title">Contáctanos</h2>
                    <List>
                      <li className="app-footer__subscribe-text">
                        <div>
                          {" "}
                          <a
                            href="https://www.facebook.com/profile.php?id=100087783186893"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icon
                              className="app-footer__subscribe-icon"
                              icon="mdi:facebook"
                            />
                          </a>
                          <a
                            href="https://www.instagram.com/bankiao_app/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icon
                              className="app-footer__subscribe-icon"
                              icon="mdi:instagram"
                            />
                          </a>
                        </div>
                      </li>
                    </List>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="app-footer__copyright">
            <p>© Copyright 2023 Bankiao. Todos los derechos reservados.</p>
          </Container>
        </Footer>
      )}
    </>
  );
};
