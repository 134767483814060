export default [
  "Directores y gerentes",
  "Directores ejecutivos, personal directivo de la administración pública y miembros del poder ejecutivo y de los cuerpos legislativos",
  "Miembros del poder ejecutivo y de los cuerpos legislativos",
  "Miembros del poder legislativo",
  "Personal directivo de la administración pública",
  "Jefes de pequeñas poblaciones",
  "Dirigentes de organizaciones que presentan un interés especial",
  "Directores generales y gerentes generales",
  "Directores administradores y comerciales",
  "Directores de administración y servicios",
  "Directores financieros",
  "Directores de recursos humanos",
  "Directores de políticas y planificación",
  "Directores de administración y servicios no clasificados bajo otros epígrafes",
  "Directores de ventas, comercialización y desarrollo",
  "Directores de ventas y comercialización",
  "Directores de publicidad y relaciones públicas",
  "Directores de investigación y desarrollo",
  "Directores y gerentes de producción y operaciones",
  "Directores de producción agropecuaria, silvicultura y pesca",
  "Directores de producción agropecuaria y silvicultura",
  "Directores de producción de piscicultura y pesca",
  "Directores de industrias manufactureras, de minería, construcción y distribución",
  "Directores de industrias manufactureras",
  "Directores de explotaciones de minería",
  "Directores de empresas de construcción",
  "Directores de empresas de abastecimiento, distribución y afines",
  "Directores de servicios de tecnología de la información y las comunicaciones",
  "Directores  y gerentes de servicios profesionales",
  "Directores de servicios de cuidados infantiles",
  "Directores de servicios de salud",
  "Directores de servicios de cuidado de las personas de edad",
  "Directores  de servicios de bienestar social",
  "Directores de servicios de educación",
  "Gerentes de sucursales de bancos, de servicios financieros y de seguros",
  "Directores y gerentes de servicios profesionales no clasificados bajo otros epígrafes",
  "Gerentes de hoteles, restaurantes, comercios y otros servicios",
  "Gerentes de hoteles y restaurantes",
  "Gerentes de hoteles",
  "Gerentes de restaurantes",
  "Gerentes de comercios al por mayor y al por menor",
  "Otros gerentes de servicios",
  "Gerentes de centros deportivos, de esparcimiento y culturales",
  "Gerentes de servicios no clasificados bajo otros epígrafes",
  "Profesionales científicos e intelectuales",
  "Profesionales de las ciencias y de la ingeniería",
  "Físicos, químicos y afines",
  "Físicos y astrónomos",
  "Meteorólogos",
  "Químicos",
  "Geólogos y geofísicos",
  "Matemáticos, actuarios y estadísticos",
  "Profesionales en ciencias biológicas",
  "Biólogos, botánicos, zoólogos y afines",
  "Agrónomos y afines",
  "Profesionales de la protección medioambiental",
  "Ingenieros (excluyendo electrotecnólogos)",
  "Ingenieros industriales y de producción",
  "Ingenieros civiles",
  "Ingenieros medioambientales",
  "Ingenieros mecánicos",
  "Ingenieros químicos",
  "Ingenieros de minas, metalúrgicos y afines",
  "Ingenieros no clasificados bajo otros epígrafes",
  "Ingenieros en electrotecnología",
  "Ingenieros electricistas",
  "Ingenieros electrónicos",
  "Ingenieros en telecomunicaciones",
  "Arquitectos, urbanistas, agrimensores y diseñadores",
  "Arquitectos",
  "Arquitectos paisajistas",
  "Diseñadores de productos y de prendas",
  "Urbanistas e ingenieros de tránsito",
  "Cartógrafos y agrimensores",
  "Diseñadores gráficos y multimedia",
  "Profesionales de la salud",
  "Médicos",
  "Médicos generales",
  "Médicos especialistas ",
  "Profesionales de enfermería y partería",
  "Profesionales de enfermería",
  "Profesionales de partería",
  "Profesionales de medicina tradicional y alternativa",
  "Practicantes paramédicos",
  "Veterinarios",
  "Otros profesionales de la salud",
  "Dentistas",
  "Farmacéuticos",
  "Profesionales de la salud y la higiene laboral y ambiental",
  "Fisioterapeutas ",
  "Dietistas y nutricionistas",
  "Audiólogos y logopedas",
  "Optometristas",
  "Profesionales de la salud no clasificados bajo otros epígrafes",
  "Profesionales de la enseñanza",
  "Profesores de universidades y de la enseñanza superior",
  "Profesores de formación profesional",
  "Profesores de enseñanza secundaria",
  "Maestros de enseñanza primaria y maestros preescolares",
  "Maestros de enseñanza primaria",
  "Maestros preescolares",
  "Otros profesionales de la enseñanza",
  "Especialistas en métodos pedagógicos",
  "Educadores para necesidades especiales",
  "Otros profesores de idiomas",
  "Otros profesores de música",
  "Otros profesores de artes",
  "Instructores en tecnología de la información",
  "Profesionales de la enseñanza no clasificados bajo otros epígrafes",
  "Especialistas en organización de la administración publica y de empresas",
  "Especialistas en finanzas",
  "Contables",
  "Asesores financieros y en inversiones",
  "Analistas financieros",
  "Especialistas en organización de administración",
  "Analistas de gestión y organización",
  "Especialistas en políticas de administración",
  "Especialistas en políticas y servicios de personal y afines",
  "Especialistas en formación del personal",
  "Profesionales de las ventas, la comercialización y las relaciones públicas",
  "Profesionales de la publicidad y la comercialización",
  "Profesionales de relaciones públicas",
  "Profesionales de ventas técnicas y médicas (excluyendo la TIC)",
  "Profesionales de ventas de tecnología de la información y las comunicaciones",
  "Profesionales de tecnología de la información y las comunicaciones",
  "Desarrolladores y analistas de software y multimedia",
  "Analistas de sistemas",
  "Desarrolladores de software",
  "Desarrolladores Web y multimedia",
  "Programadores de aplicaciones",
  "Desarrolladores y analistas de software y multimedia y analistas no clasificados bajo otros epígrafes",
  "Especialistas en bases de datos y en redes de computadores",
  "Diseñadores y administradores de bases de datos",
  "Administradores de sistemas",
  "Profesionales en redes de computadores",
  "Especialistas en bases de datos y en redes de computadores no clasificados bajo otros epígrafes",
  "Profesionales en derecho, en ciencias sociales y culturales",
  "Profesionales en derecho",
  "Abogados",
  "Jueces",
  "Profesionales en derecho no clasificados bajo otros epígrafes",
  "Archivistas, bibliotecarios, curadores y afines",
  "Archivistas y curadores de museos",
  "Bibliotecarios, documentalistas y afines",
  "Especialistas en ciencias sociales y teología",
  "Economistas",
  "Sociólogos, antropólogos y afines",
  "Filósofos, historiadores y especialistas en ciencias políticas",
  "Psicólogos",
  "Profesionales del trabajo social",
  "Profesionales religiosos",
  "Autores, periodistas y lingüistas",
  "Autores y otros escritores",
  "Periodistas",
  "Traductores, intérpretes y lingüistas",
  "Artistas creativos e interpretativos",
  "Artistas de artes plásticas",
  "Músicos, cantantes y compositores",
  "Bailarines y coreógrafos",
  "Directores de cine, de teatro y afines",
  "Actores",
  "Locutores de radio, televisión y otros medios de comunicación",
  "Artistas creativos e interpretativos no clasificados bajo otros epígrafes",
  "Técnicos y profesionales de nivel medio",
  "Profesionales de las ciencias y la ingeniería de nivel medio",
  "Técnicos en ciencias físicas y en ingeniería",
  "Técnicos en ciencias físicas y químicas",
  "Técnicos en ingeniería civil",
  "Electrotécnicos",
  "Técnicos en electrónica",
  "Técnicos en ingeniería mecánica",
  "Técnicos en química industrial",
  "Técnicos en ingeniería de minas y metalurgia",
  "Delineantes y dibujantes técnicos",
  "Técnicos en ciencias físicas y en ingeniería no clasificados bajo otros epígrafes",
  "Supervisores en ingeniería de minas, de industrias manufactureras y de la construcción",
  "Supervisores en ingeniería de minas",
  "Supervisores de industrias manufactureras",
  "Supervisores de la construcción",
  "Técnicos en control de procesos",
  "Operadores de instalaciones de producción de energía",
  "Operadores de incineradores, instalaciones de tratamiento de agua y afines",
  "Controladores de instalaciones de procesamiento de productos químicos",
  "Operadores de instalaciones de refinación de petróleo y gas natural",
  "Controladores de procesos de producción de metales",
  "Técnicos en control de procesos no clasificados bajo otros epígrafes",
  "Técnicos y profesionales de nivel medio en ciencias biológicas y afines",
  "Técnicos en ciencias biológicas (excluyendo la medicina)",
  "Técnicos agropecuarios",
  "Técnicos forestales",
  "Técnicos y controladores en navegación marítima y aeronáutica",
  "Oficiales maquinistas en navegación",
  "Capitanes, oficiales de cubierta y prácticos",
  "Pilotos de aviación y afines",
  "Controladores de tráfico aéreo",
  "Técnicos en seguridad aeronáutica",
  "Profesionales de nivel medio de la salud",
  "Técnicos médicos y farmacéuticos",
  "Técnicos en aparatos de diagnóstico y tratamiento médico",
  "Técnicos de laboratorios médicos",
  "Técnicos y asistentes farmacéuticos",
  "Técnicos de prótesis médicas y dentales",
  "Profesionales de nivel medio de enfermería y partería",
  "Profesionales de nivel medio de enfermería",
  "Profesionales de nivel medio de partería",
  "Profesionales de nivel medio de medicina tradicional y alternativa",
  "Técnicos y asistentes veterinarios",
  "Otros profesionales de nivel medio de la salud",
  "Dentistas auxiliares y ayudantes de odontología",
  "Técnicos en documentación sanitaria",
  "Trabajadores comunitarios de la salud",
  "Técnicos en optometría y ópticos",
  "Técnicos y asistentes fisioterapeutas",
  "Practicantes y asistentes médicos",
  "Inspectores de la salud laboral,  medioambiental y afines",
  "Ayudantes de ambulancias",
  "Profesionales de la salud de nivel medio no clasificados bajo otros epígrafes",
  "Profesionales de nivel medio en operaciones financieras y administrativas",
  "Profesionales de nivel medio en finanzas y matemáticas",
  "Agentes de bolsa, cambio y otros servicios financieros",
  "Oficiales de préstamos y créditos",
  "Tenedores de libros",
  "Profesionales de nivel medio de servicios estadísticos, matemáticos y afines",
  "Tasadores",
  "Agentes comerciales y corredores",
  "Agentes de seguros",
  "Representantes comerciales",
  "Agentes de compras",
  "Agentes de compras y consignatarios",
  "Agentes de servicios comerciales",
  "Declarantes o gestores de aduana",
  "Organizadores de conferencias y eventos",
  "Agentes de empleo y contratistas de mano de obra",
  "Agentes inmobiliarios",
  "Agentes de servicios comerciales no clasificados bajo otros epígrafes",
  "Secretarios administrativos y especializados",
  "Supervisores de secretaría",
  "Secretarios jurídicos",
  "Secretarios administrativos y ejecutivos",
  "Secretarios médicos",
  "Agentes de la administración pública para la aplicación de la ley y afines",
  "Agentes de aduana e inspectores de fronteras",
  "Agentes de administración tributaria",
  "Agentes de servicios de seguridad social",
  "Agentes de servicios de expedición de licencias y permisos",
  "Inspectores de policía y detectives",
  "Agentes de la administración pública para la aplicacion de la ley y afines no clasificados bajo otros epígrafes",
  "Profesionales de nivel medio de servicios jurídicos, sociales, culturales y afines",
  "Profesionales de nivel medio, de servicios jurídicos, sociales y religiosos",
  "Profesionales de nivel medio del derecho y servicios legales y afines",
  "Trabajadores y asistentes sociales de nivel medio",
  "Auxiliares laicos de las religiones",
  "Entrenadores de deportes y aptitud física",
  "Atletas y deportistas",
  "Entrenadores, instructores y árbitros de actividades deportivas",
  "Instructores de educación física y actividades recreativas",
  "Profesionales de nivel medio en actividades culturales, artísticas y culinarias",
  "Fotógrafos",
  "Diseñadores y decoradores de interior",
  "Técnicos en galerías de arte, museos y bibliotecas",
  "Chefs",
  "Otros profesionales de nivel medio en actividades culturales y artísticas",
  "Técnicos de la tecnología de la información y las comunicaciones",
  "Técnicos en operaciones de tecnología de la información y las comunicaciones y asistencia al usuario",
  "Técnicos en operaciones de tecnología de la información y las comunicaciones",
  "Técnicos en asistencia al usuario de tecnología de la información y las comunicaciones",
  "Técnicos en redes y sistemas de computadores",
  "Técnicos de la Web",
  "Técnicos en telecomunicaciones y radiodifusión",
  "Técnicos de radiodifusión y grabación audio visual",
  "Técnicos de ingeniería de las telecomunicaciones",
  "Personal de apoyo administrativo",
  "Oficinistas",
  "Oficinistas generales",
  "Secretarios (general)",
  "Operadores de máquinas de oficina",
  "Operadores de máquinas de procesamiento de texto y mecanógrafos",
  "Grabadores de datos",
  "Empleados en trato directo con el público",
  "Pagadores y cobradores de ventanilla y afines",
  "Cajeros de bancos y afines",
  "Receptores de apuestas y afines",
  "Prestamistas",
  "Cobradores y afines",
  "Empleados de servicios de información al cliente",
  "Empleados de agencias de viajes",
  "Empleados de centros de llamadas",
  "Telefonistas",
  "Recepcionistas de hoteles",
  "Empleados de ventanillas de informaciones",
  "Recepcionistas (general)",
  "Entrevistadores de encuestas y de investigaciones de mercados",
  "Empleados de servicios de información al cliente no clasificados bajo otros epígrafes",
  "Empleados contables y encargados del registro de materiales",
  "Auxiliares contables y financieros",
  "Empleados de contabilidad y cálculo de costos",
  "Empleados de servicios estadísticos, financieros y de seguros",
  "Empleados encargados de las nóminas",
  "Empleados encargados del registro de materiales y de transportes",
  "Empleados de control de abastecimientos e inventario",
  "Empleados de servicios de apoyo a la producción",
  "Empleados de servicios de transporte",
  "Otro personal de apoyo administrativo",
  "Empleados de bibliotecas",
  "Empleados de servicios de correos",
  "Codificadores de datos, correctores de pruebas de imprenta y afines",
  "Escribientes públicos y afines",
  "Empleados de archivos",
  "Empleados del servicio de personal",
  "Personal de apoyo administrativo no clasificado bajo otros epígrafes",
  "Trabajadores de los servicios y vendedores de comercios y mercados",
  "Trabajadores de los servicios personales",
  "Personal al servicio directo de los pasajeros",
  "Auxiliares de servicio de abordo",
  "Revisores y cobradores de los transportes públicos",
  "Guías de turismo",
  "Cocineros",
  "Camareros",
  "Camareros de mesas",
  "Camareros de barra",
  "Peluqueros, especialistas en tratamientos de belleza y afines",
  "Peluqueros",
  "Especialistas en tratamientos de belleza y afines",
  "Supervisores de mantenimiento y limpieza de edificios",
  "Supervisores de mantenimiento y limpieza en oficinas, hoteles y otros establecimientos",
  "Ecónomos y mayordomos domésticos ",
  "Conserjes",
  "Otros trabajadores de servicios personales",
  "Astrólogos, adivinadores y afines",
  "Acompañantes y ayudantes de cámara",
  "Personal de pompas fúnebres y embalsamadores",
  "Cuidadores de animales",
  "Instructores de autoescuela",
  "Trabajadores de servicios personales no clasificados bajo otros epígrafes",
  "Vendedores",
  "Vendedores callejeros y de puestos de mercado",
  "Vendedores de quioscos y de puestos de mercado",
  "Vendedores ambulantes de productos comestibles",
  "Comerciantes y vendedores de tiendas y almacenes",
  "Comerciantes de tiendas",
  "Supervisores de tiendas y almacenes",
  "Asistentes de venta de tiendas y almacenes",
  "Cajeros y expendedores de billetes",
  "Otros vendedores",
  "Modelos de moda, arte y publicidad",
  "Demostradores de tiendas",
  "Vendedores puerta a puerta",
  "Vendedores por teléfono",
  "Expendedores de gasolineras",
  "Vendedores de comidas al mostrador",
  "Vendedores no clasificados bajo otros epígrafes",
  "Trabajadores de los cuidados personales",
  "Cuidadores de niños y auxiliares de maestros",
  "Cuidadores de niños",
  "Auxiliares de maestros",
  "Trabajadores de los cuidados personales en servicios de salud",
  "Trabajadores de los cuidados personales en instituciones",
  "Trabajadores de los cuidados personales a domicilio",
  "Trabajadores de los cuidados personales en servicios de salud no clasificados bajo otros epígrafes",
  "Personal de los servicios de protección",
  "Bomberos",
  "Policías",
  "Guardianes de prisión",
  "Guardias de protección",
  "Personal de los servicios de protección no clasificados bajo otros epígrafes",
  "Agricultores y trabajadores calificados agropecuarios, forestales y pesqueros",
  "Agricultores y trabajadores calificados de explotaciones agropecuarias con destino al mercado",
  "Agricultores y trabajadores calificados de jardines y de cultivos para el mercado",
  "Agricultores y trabajadores calificados de cultivos extensivos",
  "Agricultores y trabajadores calificados de plantaciones de árboles y arbustos",
  "Agricultores y trabajadores calificados de huertas, invernaderos, viveros y jardines",
  "Agricultores y trabajadores calificados de cultivos mixtos",
  "Criadores y trabajadores pecuarios calificados de la cría de animales para el mercado y afines",
  "Criadores de ganado",
  "Avicultores y trabajadores calificados de la avicultura",
  "Apicultores y sericultores y trabajadores calificados de la apicultura y la sericultura",
  "Criadores y trabajadores pecuarios calificados de la cría de animales no clasificados bajo otros epígrafes",
  "Productores y trabajadores calificados de explotaciones agropecuarias mixtas cuya producción se destina al mercado",
  "Trabajadores forestales calificados, pescadores y cazadores",
  "Trabajadores forestales calificados y afines",
  "Pescadores, cazadores y tramperos",
  "Trabajadores de explotaciones de acuicultura",
  "Pescadores de agua dulce y en aguas costeras",
  "Pescadores de alta mar",
  "Cazadores y tramperos",
  "Trabajadores agropecuarios, pescadores, cazadores y recolectores de subsistencia",
  "Trabajadores agrícolas de subsistencia",
  "Trabajadores pecuarios de subsistencia",
  "Trabajadores agropecuarios de subsistencia",
  "Pescadores, cazadores, tramperos y recolectores de subsistencia",
  "Oficiales, operarios y artesanos de artes mecánicas y de otros oficios",
  "Oficiales y operarios de la construcción excluyendo electricistas",
  "Oficiales y operarios de la construcción (obra gruesa) y afines",
  "Constructores de casas",
  "Albañiles",
  "Mamposteros, tronzadores, labrantes y grabadores de piedra",
  "Operarios en cemento armado, enfoscadores y afines",
  "Carpinteros de armar y de obra blanca",
  "Oficiales y operarios de la construcción (obra gruesa) y afines no clasificados bajo otros epígrafes",
  "Oficiales y operarios de la construcción (trabajos de acabado) y afines",
  "Techadores",
  "Parqueteros y colocadores de suelos",
  "Revocadores",
  "Instaladores de material aislante y de insonorización",
  "Cristaleros",
  "Fontaneros e instaladores de tuberías",
  "Mecánicos-montadores de instalaciones de refrigeración y climatización",
  "Pintores, limpiadores de fachadas y afines",
  "Pintores y empapeladores",
  "Barnizadores y afines",
  "Limpiadores de fachadas y deshollinadores",
  "Oficiales y operarios de la metalurgia, la construcción mecánica y afines",
  "Moldeadores, soldadores, chapistas, caldereros, montadores de estructuras metálicas y afines",
  "Moldeadores y macheros",
  "Soldadores y oxicortadores",
  "Chapistas y caldereros",
  "Montadores de estructuras metálicas",
  "Aparejadores y empalmadores de cables",
  "Herreros, herramentistas y afines",
  "Herreros y forjadores",
  "Herramentistas y afines",
  "Reguladores y operadores de máquinas herramientas",
  "Pulidores de metales y afiladores de herramientas",
  "Mecánicos y reparadores de máquinas",
  "Mecánicos y reparadores de vehículos de motor",
  "Mecánicos y reparadores de motores de avión",
  "Mecánicos y reparadores de máquinas agrícolas e industriales",
  "Reparadores de bicicletas y afines",
  "Artesanos y operarios de las artes gráficas",
  "Artesanos",
  "Mecánicos y reparadores de instrumentos de precisión",
  "Fabricantes y afinadores de instrumentos musicales",
  "Joyeros, orfebres y plateros",
  "Alfareros y afines (barro, arcilla y abrasivos)",
  "Sopladores, modeladores, laminadores, cortadores y pulidores de vidrio",
  "Redactores de carteles, pintores decorativos y grabadores",
  "Artesanos en madera, cestería y materiales similares",
  "Artesanos de los tejidos, el cuero y materiales similares",
  "Artesanos no clasificados bajo otros epígrafes",
  "Oficiales y operarios de las artes gráficas",
  "Cajistas, tipógrafos y afines",
  "Impresores",
  "Encuadernadores y afines",
  "Trabajadores especializados en electricidad y la elecrotecnología",
  "Instaladores y reparadores de equipos eléctricos",
  "Electricistas de obras y afines",
  "Mecánicos y ajustadores electricistas",
  "Instaladores y reparadores de líneas eléctricas ",
  "Instaladores y reparadores de equipos electrónicos y de telecomunicaciones",
  "Mecánicos y reparadores en electrónica",
  "Instaladores y reparadores en tecnología de la información y las comunicaciones",
  "Operarios y oficiales de procesamiento de alimentos, de la confección, ebanistas, otros artesanos y afines",
  "Oficiales y operarios de procesamiento de alimentos y afines",
  "Carniceros, pescaderos y afines",
  "Panaderos, pasteleros y confiteros",
  "Operarios de la elaboración de productos lácteos",
  "Operarios de la conservación de frutas, legumbres, verduras y afines",
  "Catadores y clasificadores de alimentos y bebidas",
  "Preparadores y elaboradores de tabaco y sus productos",
  "Oficiales y operarios del tratamiento de la madera, ebanistas y afines",
  "Operarios del tratamiento de la madera",
  "Ebanistas y afines",
  "Reguladores y operadores de máquinas de labrar madera",
  "Oficiales y operarios de la confección y afines",
  "Sastres, modistos, peleteros y sombrereros",
  "Patronistas y cortadores de tela y afines",
  "Costureros, bordadores y afines",
  "Tapiceros, colchoneros y afines",
  "Apelambradores, pellejeros y curtidores",
  "Zapateros y afines",
  "Otros oficiales, operarios y artesanos de artes mecánicas y de otros oficios",
  "Buzos",
  "Dinamiteros y pegadores",
  "Clasificadores y probadores de productos (excluyendo alimentos y bebidas)",
  "Fumigadores y otros controladores de plagas y malas hierbas",
  "Oficiales, operarios y artesanos de artes mecánicas y de otros oficios no clasificados bajo otros epígrafes",
  "Operadores de instalaciones y máquinas y ensambladores",
  "Operadores de instalaciones fijas y máquinas",
  "Operadores de instalaciones mineras y de extracción y procesamiento de minerales",
  "Mineros y operadores de instalaciones mineras",
  "Operadores de instalaciones de procesamiento de minerales y rocas",
  "Perforadores y sondistas de pozos y afines",
  "Operadores de máquinas para fabricar cemento y otros productos minerales",
  "Operadores de instalaciones de procesamiento y recubridoras de metales",
  "Operadores de instalaciones de procesamiento de metales",
  "Operadores de máquinas pulidoras, galvanizadoras y recubridoras de metales ",
  "Operadores de instalaciones y máquinas de productos químicos y fotográficos",
  "Operadores de plantas y máquinas de productos químicos",
  "Operadores de máquinas para fabricar productos fotográficos",
  "Operadores de máquinas para fabricar productos de caucho, de papel y de material plástico",
  "Operadores de máquinas para fabricar productos de caucho",
  "Operadores de máquinas para fabricar productos de material plástico",
  "Operadores de máquinas para fabricar productos de papel",
  "Operadores de máquinas para fabricar productos textiles y artículos de piel y cuero",
  "Operadores de máquinas de preparación de fibras, hilado y devanado",
  "Operadores de telares y otras máquinas tejedoras",
  "Operadores de máquinas de coser",
  "Operadores de máquinas de blanqueamiento, teñido y limpieza de tejidos",
  "Operadores de máquinas de tratamiento de pieles y cueros",
  "Operadores de máquinas para la fabricación de calzado y afines",
  "Operadores de máquinas lavarropas",
  "Operadores de máquinas para fabricar productos textiles y artículos de piel y cuero no clasificados bajo otros epígrafes",
  "Operadores de máquinas para elaborar alimentos y productos afines",
  "Operadores de instalaciones para la preparación de papel y de procesamiento de la madera",
  "Operadores de instalaciones para la preparación de pasta para papel y papel",
  "Operadores de instalaciones de procesamiento de la madera",
  "Otros operadores de máquinas y de instalaciones fijas",
  "Operadores de instalaciones de vidriería y cerámica",
  "Operadores de máquinas de vapor y calderas",
  "Operadores de máquinas de embalaje, embotellamiento y etiquetado ",
  "Operadores de máquinas y de instalaciones fijas no clasificados bajo otros epígrafes",
  "Ensambladores",
  "Ensambladores de maquinaria mecánica",
  "Ensambladores de equipos eléctricos y electrónicos",
  "Ensambladores no clasificados bajo otros epígrafes",
  "Conductores de vehículos y operadores de equipos pesados móviles",
  "Maquinistas de locomotoras y afines",
  "Maquinistas de locomotoras",
  "Guardafrenos, guardagujas y agentes de maniobras",
  "Conductores de automóviles, camionetas y motocicletas",
  "Conductores de motocicletas",
  "Conductores de automóviles, taxis y camionetas",
  "Conductores de camiones pesados y autobuses",
  "Conductores de autobuses y tranvías",
  "Conductores de camiones pesados",
  "Operadores de equipos pesados móviles",
  "Operadores de maquinaria agrícola y forestal móvil",
  "Operadores de máquinas de movimiento de tierras y afines",
  "Operadores de grúas, aparatos elevadores y afines",
  "Operadores de autoelevadoras",
  "Marineros de cubierta y afines",
  "Ocupaciones elementales",
  "Limpiadores y asistentes",
  "Limpiadores y asistentes domésticos de hoteles y oficinas",
  "Limpiadores y asistentes domésticos",
  "Limpiadores y asistentes de oficinas, hoteles y otros establecimientos",
  "Limpiadores de vehículos, ventanas, ropa y otra limpieza a mano",
  "Lavanderos y planchadores manuales",
  "Lavadores de vehículos",
  "Lavadores de ventanas",
  "Otro personal de limpieza",
  "Peones agropecuarios, pesqueros y forestales",
  "Peones de explotaciones agrícolas",
  "Peones de explotaciones ganaderas",
  "Peones de explotaciones de cultivos mixtos y ganaderos",
  "Peones de jardinería y horticultura",
  "Peones forestales",
  "Peones de pesca y acuicultura",
  "Peones de la minería, la construcción, la industria manufacturera y el transporte",
  "Peones de la minería y la construcción",
  "Peones de minas y canteras",
  "Peones de obras públicas y mantenimiento",
  "Peones de la construcción de edificios",
  "Peones de la industria manufacturera",
  "Empacadores manuales",
  "Peones de la industria manufacturera no clasificados bajo otros epígrafes",
  "Peones del transporte y almacenamiento",
  "Conductores de vehículos accionados a pedal o a brazo",
  "Conductores de vehículos y máquinas de tracción animal",
  "Peones de carga",
  "Reponedores de estanterías",
  "Ayudantes de preparación de alimentos",
  "Cocineros de comidas rápidas",
  "Ayudantes de cocina",
  "Vendedores ambulantes de servicios y afines",
  "Trabajadores ambulantes de servicios y afines",
  "Vendedores ambulantes (excluyendo de comida)",
  "Recolectores de desechos y otras ocupaciones elementales",
  "Recolectores de desechos",
  "Recolectores de basura y material reciclable",
  "Clasificadores de desechos",
  "Barrenderos y afines",
  "Otro",
  "Mensajeros, mandaderos, maleteros y repartidores",
  "Personas que realizan trabajos varios",
  "Recolectores de dinero en aparatos de venta automática y lectores de medidores",
  "Acarreadores de agua y recolectores  de  leña",
  "Ocupaciones elementales no clasificadas bajo otros epígrafes",
  "Ocupaciones militares",
  "Oficiales de las fuerzas armadas",
  "Suboficiales de las fuerzas armadas",
  "Otros miembros de las fuerzas armadas"
]