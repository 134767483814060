import { Row } from "antd";
import lendPersonal from "../../assets/products/lend_personal.svg";
import lendCredito from "../../assets/products/lend_credito.svg";
import lendVehicular from "../../assets/products/lend_vehicular.svg";
import lendHipotecario from "../../assets/products/lend_hipotecario.svg";
import { ProductCard } from "./ProductCard/ProductCard";

import "./Product.scss";

export const Products = () => {
  const products = [
    { id: 1, name: "Préstamo Personal", icon: lendPersonal },
    { id: 2, name: "Préstamo Hipotecario", icon: lendHipotecario },
    { id: 3, name: "Préstamo Vehicular", icon: lendVehicular },
    { id: 4, name: "Tarjeta de Crédito", icon: lendCredito },
  ];

  return (
    <section id="products" className="products">
      <h2 className="products-heading">
        ¿Qué productos puedes obtener con ayuda de Bankiao?
      </h2>
      <Row justify="center" align="center" className="products-container">
        {products.map((product) => (
          <ProductCard
            key={product.id}
            id={product.id}
            icon={product.icon}
            name={product.name}
          />
        ))}
      </Row>
    </section>
  );
};
