import thunk from 'redux-thunk';
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { authReducer } from './reducers/authReducer';
import { requestReducer } from './reducers/requestReducer';
import { worksReducer } from './reducers/worksReducer';

const composeEnhancers =
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
  auth: authReducer,
  works: worksReducer,
  request: requestReducer
});

export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk),
  ),
);
